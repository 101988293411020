export var AuthenticationActionTypes;
(function (AuthenticationActionTypes) {
    AuthenticationActionTypes["CREATE_USER_PENDING"] = "authentication/CREATE_USER_PENDING";
    AuthenticationActionTypes["CREATE_USER_FULFILLED"] = "authentication/CREATE_USER_FULFILLED";
    AuthenticationActionTypes["CREATE_USER_REJECTED"] = "authentication/CREATE_USER_REJECTED";
    AuthenticationActionTypes["AUTHORIZE_USER_PENDING"] = "authentication/AUTHORIZE_USER_PENDING";
    AuthenticationActionTypes["AUTHORIZE_USER_FULFILLED"] = "authentication/AUTHORIZE_USER_FULFILLED";
    AuthenticationActionTypes["AUTHORIZE_USER_REJECTED"] = "authentication/AUTHORIZE_USER_REJECTED";
    AuthenticationActionTypes["LOGIN_USER_PENDING"] = "authentication/LOGIN_USER_PENDING";
    AuthenticationActionTypes["LOGIN_USER_FULFILLED"] = "authentication/LOGIN_USER_FULFILLED";
    AuthenticationActionTypes["LOGIN_USER_REJECTED"] = "authentication/LOGIN_USER_REJECTED";
    AuthenticationActionTypes["LOGOUT_USER_PENDING"] = "authentication/LOGOUT_USER_PENDING";
    AuthenticationActionTypes["LOGOUT_USER_FULFILLED"] = "authentication/LOGOUT_USER_FULFILLED";
    AuthenticationActionTypes["LOGOUT_USER_REJECTED"] = "authentication/LOGOUT_USER_REJECTED";
    AuthenticationActionTypes["SIGNUP_USER_PENDING"] = "authentication/SIGNUP_USER_PENDING";
    AuthenticationActionTypes["SIGNUP_USER_FULFILLED"] = "authentication/SIGNUP_USER_FULFILLED";
    AuthenticationActionTypes["SIGNUP_USER_REJECTED"] = "authentication/SIGNUP_USER_REJECTED";
    AuthenticationActionTypes["REFRESH_TOKEN_PENDING"] = "authentication/REFRESH_TOKEN_PENDING";
    AuthenticationActionTypes["REFRESH_TOKEN_FULFILLED"] = "authentication/REFRESH_TOKEN_FULFILLED";
    AuthenticationActionTypes["REFRESH_TOKEN_REJECTED"] = "authentication/REFRESH_TOKEN_REJECTED";
    AuthenticationActionTypes["RESET_LOGIN"] = "authentication/RESET_LOGIN";
    AuthenticationActionTypes["RESET_SIGNUP"] = "authentication/RESET_SIGNUP";
    AuthenticationActionTypes["SET_LOGOUT_TIME_INIT"] = "authentication/SET_LOGOUT_TIME_INIT";
    AuthenticationActionTypes["SET_LOGOUT_TIME_COMPLETE"] = "authentication/SET_LOGOUT_TIME_COMPLETE";
    AuthenticationActionTypes["MPOWER_DATA_PENDING"] = "authentication/MPOWER_DATA_PENDING";
    AuthenticationActionTypes["MPOWER_DATA_FULFILLED"] = "authentication/MPOWER_DATA_FULFILLED";
    AuthenticationActionTypes["MPOWER_DATA_REJECTED"] = "authentication/MPOWER_DATA_REJECTED";
    AuthenticationActionTypes["RESET_MPOWER"] = "authentication/RESET_MPOWER";
})(AuthenticationActionTypes || (AuthenticationActionTypes = {}));
