import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signupUserPending, resetSignup } from '../../store/authentication/actions';
import Signup from './Signup';
var mapStateToProps = function (state) { return ({
    signupUserRequestPending: state.authenticationReducer.signupUserRequestPending,
    signupUserRequestError: state.authenticationReducer.signupUserRequestError,
    signupUserRequestFulfilled: state.authenticationReducer.signupUserRequestFulfilled,
    user: state.authenticationReducer.user,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        signupUserPending: signupUserPending,
        resetSignup: resetSignup,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
