import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authorizeUserPending, loginUserPending, logoutUserPending, resetLogin, } from '../../store/authentication/actions';
import Login from './Login';
var mapStateToProps = function (state) { return ({
    authorizeUserRequestError: state.authenticationReducer.authorizeUserRequestError,
    userAuthorized: state.authenticationReducer.userAuthorized,
    loginUserRequestPending: state.authenticationReducer.loginUserRequestPending,
    loginUserRequestFulfilled: state.authenticationReducer.loginUserRequestFulfilled,
    loginUserRequestError: state.authenticationReducer.loginUserRequestError,
    user: state.authenticationReducer.user,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        authorizeUserPending: authorizeUserPending,
        loginUserPending: loginUserPending,
        logoutUserPending: logoutUserPending,
        resetLogin: resetLogin,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
