import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { DropdownSearchInput } from '@lumera/lumera-ui';
import ReactGA from 'react-ga';
import CrossIcon from '../../svgs/CrossIcon';
import LoginHeader from '../../components/LoginHeader/LoginHeader';
import SignedUpModal from '../../components/SignedUpModal/SignedUpModal';
import './Signup.scss';
var Signup = function (_a) {
    var signupUserPending = _a.signupUserPending, signupUserRequestPending = _a.signupUserRequestPending, signupUserRequestError = _a.signupUserRequestError, signupUserRequestFulfilled = _a.signupUserRequestFulfilled, resetSignup = _a.resetSignup, user = _a.user;
    var history = useHistory();
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), firstName = _c[0], setFirstName = _c[1];
    var _d = useState(''), lastName = _d[0], setLastName = _d[1];
    var _e = useState(''), newPassword = _e[0], setNewPassword = _e[1];
    var _f = useState(''), confirmPassword = _f[0], setConfirmPassword = _f[1];
    var _g = useState(''), passwordError = _g[0], setPasswordError = _g[1];
    var _h = useState(false), disabled = _h[0], setDisabled = _h[1];
    var _j = useState(''), signupError = _j[0], setSignupError = _j[1];
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    useEffect(function () {
        if (newPassword !== '' && confirmPassword !== '' && newPassword !== confirmPassword) {
            setPasswordError('Passwords must match');
        }
        else {
            setPasswordError('');
        }
    }, [confirmPassword, newPassword]);
    useEffect(function () {
        if (passwordError !== '' ||
            firstName === '' ||
            lastName === '' ||
            confirmPassword === '' ||
            email === '' ||
            newPassword === '') {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [email, firstName, lastName, newPassword, confirmPassword, passwordError]);
    useEffect(function () {
        if (signupUserRequestError) {
            setSignupError((signupUserRequestError === null || signupUserRequestError === void 0 ? void 0 : signupUserRequestError.message) || (signupUserRequestError === null || signupUserRequestError === void 0 ? void 0 : signupUserRequestError.description));
        }
        else {
            setSignupError('');
        }
    }, [signupUserRequestError]);
    useEffect(function () {
        if (passwordError || signupError) {
            resetSignup();
        }
    }, [newPassword, confirmPassword, email, firstName, lastName]);
    if (user)
        return React.createElement(Redirect, { to: history.location.pathname });
    return (React.createElement("div", { className: "signup-modal" }, signupUserRequestFulfilled ? (React.createElement(SignedUpModal, { resetSignup: resetSignup })) : (React.createElement("div", { className: "signup-modal__modal-form" },
        React.createElement("div", { className: "signup-modal__modal-form__header" },
            React.createElement("div", { className: "signup-modal__modal-form__header__container" },
                React.createElement(LoginHeader, { text: "Sign up", selected: history.location.hash === '#signup', setView: function () { return history.push('/#signup'); } }),
                React.createElement(LoginHeader, { text: "Login", selected: history.location.hash === '#login', setView: function () { return history.push('/#login'); } })),
            React.createElement(CrossIcon, { onClick: function () { return history.push(history.location.pathname); } })),
        React.createElement("form", { className: "signup-modal__signup-form", autoComplete: "off", onSubmit: function (e) { return e.preventDefault(); }, role: "presentation" },
            React.createElement("div", { className: "signup-modal__signup-form__content" },
                React.createElement("div", { className: "signup-modal__signup-form__content__grid" },
                    React.createElement(DropdownSearchInput, { labelText: "FIRST NAME", value: firstName, placeholder: "John", onChange: function (e) { return setFirstName(e); }, required: true, name: "firstName" }),
                    React.createElement(DropdownSearchInput, { labelText: "LAST NAME", value: lastName, placeholder: "Smith", onChange: function (e) { return setLastName(e); }, required: true, name: "lastName" })),
                React.createElement(DropdownSearchInput, { labelText: "EMAIL", value: email, placeholder: "example@email.com", onChange: function (e) { return setEmail(e); }, required: true, name: "email", errorTag: signupError }),
                React.createElement(DropdownSearchInput, { type: "password", labelText: "PASSWORD", value: newPassword, placeholder: "***********", onChange: function (e) { return setNewPassword(e); }, required: true, name: "password-input", errorTag: passwordError || signupError }),
                React.createElement(DropdownSearchInput, { type: "password", labelText: "CONFIRM PASSWORD", value: confirmPassword, placeholder: "***********", onChange: function (e) { return setConfirmPassword(e); }, required: true, name: "confirmPassword", errorTag: passwordError || signupError })),
            React.createElement("div", { className: "signup-modal__signup-form__bottom" },
                React.createElement("button", { type: "submit", disabled: disabled || signupUserRequestPending, className: disabled
                        ? 'signup-modal__signup-form__bottom__signup-button signup-modal__signup-form__bottom__signup-button--disabled'
                        : 'signup-modal__signup-form__bottom__signup-button', onClick: function () {
                        return signupUserPending({
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            password: newPassword,
                        });
                    } }, "Sign up")))))));
};
export default Signup;
