var User = /** @class */ (function () {
    function User(user) {
        this.id = user.id;
        this.firstName = user.firstName || '';
        this.lastName = user.lastName || '';
        this.email = user.email || '';
    }
    return User;
}());
export { User };
