import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { getRecommendationsPending } from '../../store/recommendations/actions';
import { getAllQuestionnaireSummariesPending } from '../../store/assessment/actions';
var mapStateToProps = function (state) { return ({
    getRecommendationsRequestFulfilled: state.recommendationsReducer.getRecommendationsRequestFulfilled,
    getRecommendationsRequestPending: state.recommendationsReducer.getRecommendationsRequestPending,
    getAllQuestionnaireSummariesRequestPending: state.assessmentReducer.getAllQuestionnaireSummariesRequestPending,
    questionnaires: state.assessmentReducer.questionnaires,
    recommendations: state.recommendationsReducer.recommendations,
    refreshTokenRequestPending: state.authenticationReducer.refreshTokenRequestPending,
    user: state.authenticationReducer.user,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        getAllQuestionnaireSummariesPending: getAllQuestionnaireSummariesPending,
        getRecommendationsPending: getRecommendationsPending,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
