import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { RecommendationsActionTypes, } from './types';
import { getRecommendationsFulfilled, getRecommendationsRejected } from './actions';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
var getRecommendationsEpic = function (action$, state$) {
    return action$.pipe(ofType(RecommendationsActionTypes.GET_RECOMMENDATIONS_PENDING), switchMap(function () {
        var _a;
        var userId = state$.value.authenticationReducer.user
            ? state$.value.authenticationReducer.user.id
            : (_a = state$.value.authenticationReducer.tempUser) === null || _a === void 0 ? void 0 : _a.id;
        return ajax.get(process.env.USER_BACKEND_URL + "/recommendations/" + userId).pipe(map(function (response) {
            return getRecommendationsFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getRecommendationsRejected(error));
        }));
    }));
};
export default combineEpics(getRecommendationsEpic);
