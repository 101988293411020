var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MPowerActionTypes, } from './types';
// export interface MPowerState {
//   mPowerDataRequestPending: boolean
//   mPowerDataRequestError: Error | undefined
//   mPowerDataRequestFulfilled: boolean
//   refreshTokenRequestPending: boolean
//   refreshTokenRequestFulfilled: boolean
//   refreshTokenRequestError: Error | null
//   mPowerDataOK: boolean
//   headers: Headers
// }
var initialState = {
    mPowerRecord: undefined,
    mPowerDataRequestPending: false,
    mPowerDataRequestError: undefined,
    mPowerDataRequestFulfilled: false,
    refreshTokenRequestPending: false,
    refreshTokenRequestFulfilled: false,
    refreshTokenRequestError: null,
    mPowerDataOK: true,
    headers: {
        Authorization: undefined,
        'Content-Type': 'application/json',
    },
};
export default function mPowerReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MPowerActionTypes.MPOWER_DATA_PENDING:
            return __assign(__assign({}, state), { mPowerDataRequestPending: true, mPowerDataRequestFulfilled: false, mPowerDataRequestError: undefined });
        case MPowerActionTypes.MPOWER_DATA_FULFILLED:
            return __assign(__assign({}, state), { mPowerDataRequestPending: false, mPowerDataRequestFulfilled: true, mPowerDataRequestError: undefined });
        case MPowerActionTypes.MPOWER_DATA_REJECTED:
            return __assign(__assign({}, state), { mPowerDataRequestPending: false, mPowerDataRequestFulfilled: false, mPowerDataRequestError: action.payload.response.message });
        case MPowerActionTypes.RESET_MPOWER:
            return __assign(__assign({}, state), { mPowerDataRequestPending: false, mPowerDataRequestFulfilled: false, mPowerDataRequestError: undefined });
        case MPowerActionTypes.REFRESH_TOKEN_PENDING:
            return __assign(__assign({}, state), { refreshTokenRequestPending: true, refreshTokenRequestFulfilled: false, refreshTokenRequestError: null });
        case MPowerActionTypes.REFRESH_TOKEN_FULFILLED:
            return __assign(__assign({}, state), { refreshTokenRequestPending: false, refreshTokenRequestFulfilled: true, refreshTokenRequestError: null, mPowerDataOK: true, mPowerDataRequestFulfilled: true, mPowerRecord: action.payload.mPowerRecord, headers: __assign(__assign({}, state.headers), { Authorization: "Bearer " + action.payload.tokens.access_token }) });
        case MPowerActionTypes.REFRESH_TOKEN_REJECTED:
            return __assign(__assign({}, state), { refreshTokenRequestPending: false, refreshTokenRequestFulfilled: false, refreshTokenRequestError: action.payload });
        default:
            return state;
    }
}
