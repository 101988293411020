import React from 'react';
import './ReviewBubble.scss';
var ReviewBubble = function (_a) {
    var compositeReviewString = _a.compositeReviewString, reviewProp = _a.reviewProp;
    return (React.createElement("div", { className: "review-bubble" },
        React.createElement("div", { className: "review-bubble__text" },
            React.createElement("div", { className: "review-bubble__image-container" },
                React.createElement("img", { className: "review-bubble__reviewer-image", src: (reviewProp(compositeReviewString, 4).length > 0) ? reviewProp(compositeReviewString, 4) : "https://adam-image-bucket.s3.eu-west-2.amazonaws.com/a-new-placeholder-img-0.png" })),
            React.createElement("div", { className: "review-bubble__review-text" }, reviewProp(compositeReviewString, 1)),
            React.createElement("div", { className: "review-bubble__name-or-role" },
                "\u00A0-\u00A0",
                reviewProp(compositeReviewString, 0)))));
};
export default ReviewBubble;
