import React from 'react';
import MultiSelectContainer from '../MultiSelectContainer/MultiSelectContainer';
import ImageSelectContainer from '../ImageSelectContainer/ImageSelectContainer';
import ScaleContainer from '../ScaleContainer/ScaleContainer';
import './QuestionContainer.scss';
var QuestionContainer = function (_a) {
    var currentQuestion = _a.currentQuestion, selectAnswer = _a.selectAnswer, selectedAnswers = _a.selectedAnswers, deselectAnswer = _a.deselectAnswer;
    switch (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.questionType) {
        case 'MultiSelectQuestion':
            return (React.createElement(MultiSelectContainer, { selectedAnswers: selectedAnswers, selectAnswer: selectAnswer, deselectAnswer: deselectAnswer, currentQuestion: currentQuestion, answers: currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers }));
        case 'ScaleQuestion':
        case 'BinaryQuestion':
        case 'RadioQuestion':
            return (React.createElement(ScaleContainer, { selectedAnswers: selectedAnswers, selectAnswer: selectAnswer, deselectAnswer: deselectAnswer, currentQuestion: currentQuestion, answers: currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers }));
        case 'image':
            return (React.createElement(ImageSelectContainer, { currentQuestion: currentQuestion, answers: currentQuestion.answers }));
        default:
            return null;
    }
};
export default QuestionContainer;
