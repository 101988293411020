import { ProductCatalogueActionTypes, } from './types';
export var getProductCataloguePending = function () { return ({
    type: ProductCatalogueActionTypes.GET_PRODUCT_CATALOGUE_PENDING,
}); };
export var getProductCatalogueFulfilled = function (payload) { return ({
    type: ProductCatalogueActionTypes.GET_PRODUCT_CATALOGUE_FULFILLED,
    payload: payload,
}); };
export var getProductCatalogueRejected = function (error) { return ({
    type: ProductCatalogueActionTypes.GET_PRODUCT_CATALOGUE_REJECTED,
    payload: error,
}); };
