import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import RightArrow from '../../svgs/RightArrow';
import Tick from '../../svgs/Tick';
//import Lock from '../../svgs/Lock'
import './ThemeList.scss';
var ThemeList = function (_a) {
    var questionnaires = _a.questionnaires, setCurrentQuestionnaireSummary = _a.setCurrentQuestionnaireSummary, currentQuestionnaireSummary = _a.currentQuestionnaireSummary, setThemeComplete = _a.setThemeComplete, setMenuOpen = _a.setMenuOpen;
    // const aboutMeComplete = questionnaires[0].getCompletionPercentage() === 100
    // const anyQuestionnaireComplete = 
    // questionnaires[0].getCompletionPercentage() === 100
    // || questionnaires[1].getCompletionPercentage() === 100
    // || questionnaires[2].getCompletionPercentage() === 100
    // || questionnaires[3].getCompletionPercentage() === 100
    // || questionnaires[4].getCompletionPercentage() === 100
    // || questionnaires[5].getCompletionPercentage() === 100
    // || questionnaires[6].getCompletionPercentage() === 100
    // || questionnaires[7].getCompletionPercentage() === 100
    var returnIcon = function (questionnaire) {
        if (questionnaire.getCompletionPercentage() === 100) {
            return (React.createElement(Tick, { fill: currentQuestionnaireSummary && currentQuestionnaireSummary.name === questionnaire.name
                    ? '#FFFFFF'
                    : '#6F6ADB' }));
        }
        // if (!aboutMeComplete && questionnaire.name !== 'About Me') {
        //   return (
        //     <Lock
        //       fill={
        //         currentQuestionnaireSummary && currentQuestionnaireSummary.name === questionnaire.name
        //           ? '#FFFFFF'
        //           : '#CCC9F7'
        //       }
        //     />
        //   )
        // }
        if (questionnaire.getCompletionPercentage() <= 100 &&
            questionnaire.getCompletionPercentage() !== 0) {
            return (React.createElement(CircularProgressbar, { value: questionnaire.getCompletionPercentage(), strokeWidth: 50, styles: buildStyles({
                    strokeLinecap: 'butt',
                    trailColor: '#EFEEFF',
                    pathColor: '#6f6adb',
                }) }));
        }
        return (React.createElement(RightArrow, { fill: currentQuestionnaireSummary && currentQuestionnaireSummary.name === questionnaire.name
                ? '#FFFFFF'
                : '#6F6ADB' }));
    };
    var themeNameStyle = function (qs, q) {
        var style = '';
        if (qs && qs.name === q.name) {
            style = 'theme-list-container__theme-item theme-list-container__theme-item--selected';
        }
        else if (q.getCompletionPercentage() === 100) {
            style = 'theme-list-container__theme-item theme-list-container__theme-item--grey';
        }
        else {
            style = 'theme-list-container__theme-item';
        }
        return style;
    };
    return (React.createElement("div", { className: "theme-list-container" }, questionnaires &&
        questionnaires.map(function (questionnaire) { return (React.createElement("div", { key: questionnaire.id, role: "button", onClick: function () {
                setCurrentQuestionnaireSummary(questionnaire);
                setThemeComplete(false);
                setMenuOpen(false);
            }, className: themeNameStyle(currentQuestionnaireSummary, questionnaire) },
            questionnaire.name,
            returnIcon(questionnaire))); })));
};
export default ThemeList;
