import React from 'react';
import { useHistory } from 'react-router';
import { CrossIcon, CtaButton } from '@lumera/ts-lumera-ui';
import Modal from '../Modal/Modal';
import './ZeroRecommendationsModal.scss';
var ZeroRecommendationsModal = function () {
    var history = useHistory();
    return (React.createElement(Modal, { aboveNav: true, lightBackground: true },
        React.createElement("div", { className: "modal-content" },
            React.createElement("div", { className: "modal-content__top" },
                React.createElement("div", { className: "modal-content__top__header" }, "No recommendations found"),
                React.createElement("div", { className: "modal-content__top__header__icon-container", role: "button", onClick: function () { return history.go(-1); } },
                    React.createElement(CrossIcon, null))),
            React.createElement("div", { className: "modal-content__paragraph" }, "If you answer a few more questions, we\u2019ll be able to provide a few recommendations for you."),
            React.createElement("div", { className: "modal-content__line" }),
            React.createElement("div", { className: "modal-content__button-grid" },
                React.createElement(CtaButton, { text: "Exit", onClick: function () { return history.push('/'); } }),
                React.createElement(CtaButton, { text: "Answer more questions", onClick: function () {
                        history.push('/assessment');
                    } })))));
};
export default ZeroRecommendationsModal;
