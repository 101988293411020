import React from 'react';
import { CtaButton } from '@lumera/ts-lumera-ui';
import { useHistory } from 'react-router-dom';
import './FinishedTheme.scss';
var FinishedTheme = function (_a) {
    var currentQuestionnaireSummary = _a.currentQuestionnaireSummary, setCurrentQuestionnaireSummary = _a.setCurrentQuestionnaireSummary, questionnaires = _a.questionnaires, nextIndex = _a.nextIndex, setThemeComplete = _a.setThemeComplete, setMenuOpen = _a.setMenuOpen, tablet = _a.tablet;
    var history = useHistory();
    // const aboutMeSection = currentQuestionnaireSummary?.name === 'About Me'
    // const aboutMeComplete = questionnaires[0].getCompletionPercentage() === 100
    // const anyQuestionnaireComplete = 
    // questionnaires[0].getCompletionPercentage() === 100
    // || questionnaires[1].getCompletionPercentage() === 100
    // || questionnaires[2].getCompletionPercentage() === 100
    // || questionnaires[3].getCompletionPercentage() === 100
    // || questionnaires[4].getCompletionPercentage() === 100
    // || questionnaires[5].getCompletionPercentage() === 100
    // || questionnaires[6].getCompletionPercentage() === 100
    // || questionnaires[7].getCompletionPercentage() === 100
    var aboutMeText = function () {
        var text = "I've got some recommendations for you now, but try completing the remaining sections for even better results. Click a section on the left to continue.";
        // let text = ''
        // if (aboutMeSection && aboutMeComplete) {
        //   text =
        //     "Try completing one of the other sections and I'll be able to give you some recommendations. Click a section on the left to continue."
        // } else if (!aboutMeComplete) {
        //   text =
        //     'You first need to complete every question in the About Me section before you can move on to the other sections.'
        // } else {
        //   text =
        //     "I've got some recommendations for you now, but try completing the remaining sections for even better results. Click a section on the left to continue."
        // }
        return text;
    };
    var aboutMeTextMobile = function () {
        var text = "I've got some recommendations for you now, but try completing the remaining sections for even better results. Choose another section from the menu to continue.";
        // let text = ''
        // if (aboutMeSection && aboutMeComplete) {
        //   text =
        //     "Try completing one of the other sections and I'll be able to give you some recommendations. Choose another section from the menu to continue."
        // } else if (!aboutMeComplete) {
        //   text =
        //     'You first need to complete every question in the About Me section before you can move on to the other sections.'
        // } else {
        //   text =
        //     "I've got some recommendations for you now, but try completing the remaining sections for even better results. Choose another section from the menu to continue."
        // }
        return text;
    };
    return (React.createElement("div", { className: "finish-theme-container" },
        React.createElement("div", { className: "finish-theme-container__description-content" },
            React.createElement("img", { src: "./images/celebrate.png", alt: "theme-icons", className: "finish-theme-container__description-content__image" }),
            React.createElement("h3", { className: "finish-theme-container__description-content__header" }, false ? ('Please complete all the questions in the About Me section to move on to the other sections.') : (React.createElement("div", null,
                React.createElement("strong", null, "Well done!"),
                " You've completed the section on",
                ' ', currentQuestionnaireSummary === null || currentQuestionnaireSummary === void 0 ? void 0 :
                currentQuestionnaireSummary.name,
                "."))),
            React.createElement("div", { className: "finish-theme-container__description-content__paragraph" }, tablet ? aboutMeTextMobile() : aboutMeText()),
            false ? (React.createElement("div", { className: "finish-theme-container__description-content__button-container" },
                React.createElement(CtaButton, { text: "Continue", onClick: function () {
                        setCurrentQuestionnaireSummary(questionnaires[1]);
                        setThemeComplete(false);
                        setMenuOpen(true);
                    } }))) : (React.createElement("div", { className: "finish-theme-container__description-content__button-grid" },
                React.createElement(CtaButton, { text: "Finish now", onClick: function () { return history.push('/recommendations'); } }),
                React.createElement(CtaButton, { text: "Get better results", onClick: function () {
                        setCurrentQuestionnaireSummary(questionnaires[nextIndex]);
                        setThemeComplete(false);
                        setMenuOpen(true);
                    } }))))));
};
export default FinishedTheme;
