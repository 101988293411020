import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { CtaButton } from '@lumera/ts-lumera-ui';
import ReactGA from 'react-ga';
import RecommendationCard from '../../components/RecommendationCard/RecommendationCard';
import indexToString from '../../utils/indexToString';
import './Dashboard.scss';
var Dashboard = function (_a) {
    var getAllQuestionnaireSummariesPending = _a.getAllQuestionnaireSummariesPending, getAllQuestionnaireSummariesRequestPending = _a.getAllQuestionnaireSummariesRequestPending, getRecommendationsPending = _a.getRecommendationsPending, getRecommendationsRequestPending = _a.getRecommendationsRequestPending, questionnaires = _a.questionnaires, recommendations = _a.recommendations, refreshTokenRequestPending = _a.refreshTokenRequestPending, user = _a.user;
    var history = useHistory();
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        if (user) {
            getRecommendationsPending();
            getAllQuestionnaireSummariesPending();
        }
        if (!user) {
            history.push('/');
        }
    }, [user]);
    if ((!user && refreshTokenRequestPending) ||
        getRecommendationsRequestPending ||
        getAllQuestionnaireSummariesRequestPending) {
        return React.createElement("h1", null, "loading");
    }
    var isNew = questionnaires.every(function (questionnaire) { return questionnaire.getCompletionPercentage() === 0; });
    var isComplete = questionnaires.every(function (questionnaire) { return questionnaire.getCompletionPercentage() === 100; });
    var isInProgress = !(isNew || isComplete);
    return (React.createElement("div", { className: "dashboard-page" },
        React.createElement("div", { className: "questionnaire-cta" },
            isNew && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "questionnaire-cta__title" }, "Welcome, " + (user === null || user === void 0 ? void 0 : user.firstName) + "!"),
                React.createElement("p", { className: "questionnaire-cta__text" },
                    "Start your questionnaire to receive",
                    React.createElement("br", null),
                    " recommendations based on your needs."),
                React.createElement(CtaButton, { onClick: function () { return history.push('/assessment'); }, text: "Get started" }))),
            isInProgress && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "questionnaire-cta__title" }, "Keep going, " + (user === null || user === void 0 ? void 0 : user.firstName) + "!"),
                React.createElement("p", { className: "questionnaire-cta__text" },
                    "Continue to work on your questionnaire.",
                    React.createElement("br", null),
                    " The more answers you give, the more I can give."),
                React.createElement(CtaButton, { onClick: function () { return history.push('/assessment'); }, text: "Continue" }))),
            isComplete && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "questionnaire-cta__title" }, "You're up to date"),
                React.createElement("p", { className: "questionnaire-cta__text" },
                    "Sometimes things can change, why not check",
                    React.createElement("br", null),
                    " back in 6 months to se how you're getting on."),
                React.createElement(CtaButton, { onClick: function () { return history.push('/assessment'); }, text: "Update answers" })))),
        React.createElement("div", { className: "dashboard-page__recommendations-list" }, recommendations.map(function (recommendation, index) { return (React.createElement(Fragment, { key: index },
            React.createElement("div", { className: "dashboard-page__recommendations-list__recommendation-title" },
                "Here's your ",
                React.createElement("strong", null, indexToString(index)),
                " recommendation"),
            React.createElement(RecommendationCard, { key: recommendation.product.id, recommendation: recommendation, onClick: function () { return history.push("/profile/" + recommendation.product.id); } }))); })),
        React.createElement("footer", { className: "footer" },
            React.createElement("img", { src: "/images/footer-illustrations.png", alt: "footer decorations", className: "footer__illustrations" }))));
};
export default Dashboard;
