var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FAQsActionTypes } from './types';
var initialState = {
    faqs: [],
    getFAQsRequestPending: false,
    getFAQsRequestFulfilled: false,
    getFAQsRequestError: undefined,
};
export default function faqsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FAQsActionTypes.GET_FAQS_PENDING:
            return __assign(__assign({}, state), { getFAQsRequestPending: true, getFAQsRequestFulfilled: false });
        case FAQsActionTypes.GET_FAQS_FULFILLED:
            return __assign(__assign({}, state), { getFAQsRequestPending: false, getFAQsRequestFulfilled: true, faqs: action.payload });
        case FAQsActionTypes.GET_FAQS_REJECTED:
            return __assign(__assign({}, state), { getFAQsRequestPending: true, getFAQsRequestFulfilled: false });
        default:
            return state;
    }
}
