import React from 'react';
import { AbcCheckBox, ProductTitle } from '@lumera/ts-lumera-ui';
import indexToAbc from '../../utils/indexToAbc';
import './MultiSelectContainer.scss';
var MultiSelectContainer = function (_a) {
    var answers = _a.answers, currentQuestion = _a.currentQuestion, selectAnswer = _a.selectAnswer, deselectAnswer = _a.deselectAnswer, selectedAnswers = _a.selectedAnswers;
    return (React.createElement("div", { className: "multi-question-container" },
        React.createElement(ProductTitle, { title: (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.text[0]) || '', subheader: (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.info) || '' }),
        (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.text[1]) && (React.createElement("h2", { className: "scale-question-container__sub-heading" }, currentQuestion.text[1])),
        React.createElement("div", { className: "multi-question-container__multi-select-container" }, answers &&
            answers.map(function (answer, index) {
                return (React.createElement(AbcCheckBox, { onChange: function () {
                        if (selectedAnswers.includes(answer.id)) {
                            deselectAnswer(answer.id);
                        }
                        else {
                            selectAnswer(answer.id);
                        }
                    }, checked: selectedAnswers.includes(answer.id), id: answer.id, required: false, name: answer.text, option: answer.text, key: answer.id, letter: indexToAbc(index) }));
            }))));
};
export default MultiSelectContainer;
