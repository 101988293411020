import { ProductProfileActionTypes, } from './types';
console.log("*** productProfile : actions.ts ***");
export var getProductProfilePending = function (payload) { return ({
    type: ProductProfileActionTypes.GET_PRODUCT_PROFILE_PENDING,
    payload: payload,
}); };
export var getProductProfileFulfilled = function (payload) { return ({
    type: ProductProfileActionTypes.GET_PRODUCT_PROFILE_FULFILLED,
    payload: payload,
}); };
export var getProductProfileRejected = function (error) { return ({
    type: ProductProfileActionTypes.GET_PRODUCT_PROFILE_REJECTED,
    payload: error,
}); };
export var setCurrentProduct = function (payload) { return ({
    type: ProductProfileActionTypes.SET_CURRENT_PRODUCT,
    payload: payload,
}); };
export var resetProductProfile = function () { return ({
    type: ProductProfileActionTypes.RESET_PRODUCT_PROFILE,
}); };
