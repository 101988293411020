var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthenticationActionTypes, } from './types';
var initialState = {
    user: undefined,
    tempUser: undefined,
    createUserRequestPending: false,
    createUserRequestFulfilled: false,
    createUserRequestError: null,
    signupUserRequestPending: false,
    signupUserRequestFulfilled: false,
    signupUserRequestError: undefined,
    loginUserRequestPending: false,
    loginUserRequestFulfilled: false,
    loginUserRequestError: undefined,
    logoutTime: undefined,
    logoutUserRequestPending: false,
    logoutUserRequestFulfilled: false,
    logoutUserRequestError: undefined,
    authorizeUserRequestPending: false,
    authorizeUserRequestFulfilled: false,
    authorizeUserRequestError: null,
    refreshTokenRequestPending: false,
    refreshTokenRequestFulfilled: false,
    refreshTokenRequestError: null,
    userAuthorized: true,
    headers: {
        Authorization: undefined,
        'Content-Type': 'application/json',
    },
};
export default function authenticationReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthenticationActionTypes.SIGNUP_USER_PENDING:
            return __assign(__assign({}, state), { signupUserRequestPending: true, signupUserRequestFulfilled: false, signupUserRequestError: undefined });
        case AuthenticationActionTypes.SIGNUP_USER_FULFILLED:
            return __assign(__assign({}, state), { signupUserRequestPending: false, signupUserRequestFulfilled: true, signupUserRequestError: undefined });
        case AuthenticationActionTypes.SIGNUP_USER_REJECTED:
            return __assign(__assign({}, state), { signupUserRequestPending: false, signupUserRequestFulfilled: false, signupUserRequestError: action.payload.response.message });
        case AuthenticationActionTypes.RESET_SIGNUP:
            return __assign(__assign({}, state), { signupUserRequestPending: false, signupUserRequestFulfilled: false, signupUserRequestError: undefined });
        case AuthenticationActionTypes.LOGIN_USER_PENDING:
            return __assign(__assign({}, state), { loginUserRequestPending: true, loginUserRequestFulfilled: false, loginUserRequestError: undefined });
        case AuthenticationActionTypes.LOGIN_USER_FULFILLED:
            return __assign(__assign({}, state), { loginUserRequestPending: false, loginUserRequestFulfilled: true, loginUserRequestError: undefined, user: action.payload.user, headers: __assign(__assign({}, state.headers), { Authorization: "Bearer " + action.payload.tokens.access_token }) });
        case AuthenticationActionTypes.LOGIN_USER_REJECTED:
            return __assign(__assign({}, state), { loginUserRequestPending: false, loginUserRequestFulfilled: false, loginUserRequestError: action.payload.response.message });
        case AuthenticationActionTypes.RESET_LOGIN:
            return __assign(__assign({}, state), { loginUserRequestPending: false, loginUserRequestFulfilled: false, loginUserRequestError: undefined });
        case AuthenticationActionTypes.LOGOUT_USER_PENDING:
            return __assign(__assign({}, state), { logoutUserRequestPending: true, logoutUserRequestFulfilled: false, logoutUserRequestError: undefined });
        case AuthenticationActionTypes.LOGOUT_USER_FULFILLED:
            return __assign(__assign({}, state), { logoutUserRequestPending: false, logoutUserRequestFulfilled: true, user: undefined, headers: __assign(__assign({}, state.headers), { Authorization: undefined }), logoutUserRequestError: undefined, logoutTime: undefined });
        case AuthenticationActionTypes.LOGOUT_USER_REJECTED:
            return __assign(__assign({}, state), { logoutUserRequestPending: false, logoutUserRequestFulfilled: false, logoutUserRequestError: action.payload });
        case AuthenticationActionTypes.SET_LOGOUT_TIME_COMPLETE:
            return __assign(__assign({}, state), { logoutTime: action.payload });
        case AuthenticationActionTypes.CREATE_USER_PENDING:
            return __assign(__assign({}, state), { createUserRequestPending: true, createUserRequestFulfilled: false, createUserRequestError: null });
        case AuthenticationActionTypes.CREATE_USER_FULFILLED:
            return __assign(__assign({}, state), { createUserRequestPending: false, createUserRequestFulfilled: true, createUserRequestError: null, tempUser: action.payload });
        case AuthenticationActionTypes.CREATE_USER_REJECTED:
            return __assign(__assign({}, state), { createUserRequestPending: false, createUserRequestFulfilled: false, createUserRequestError: action.payload });
        case AuthenticationActionTypes.AUTHORIZE_USER_PENDING:
            return __assign(__assign({}, state), { authorizeUserRequestPending: true, authorizeUserRequestFulfilled: false, authorizeUserRequestError: null });
        case AuthenticationActionTypes.AUTHORIZE_USER_FULFILLED:
            return __assign(__assign({}, state), { authorizeUserRequestPending: false, authorizeUserRequestFulfilled: true, authorizeUserRequestError: null, userAuthorized: true });
        case AuthenticationActionTypes.AUTHORIZE_USER_REJECTED:
            return __assign(__assign({}, state), { authorizeUserRequestPending: false, authorizeUserRequestFulfilled: false, authorizeUserRequestError: action.payload, userAuthorized: false });
        case AuthenticationActionTypes.REFRESH_TOKEN_PENDING:
            return __assign(__assign({}, state), { refreshTokenRequestPending: true, refreshTokenRequestFulfilled: false, refreshTokenRequestError: null });
        case AuthenticationActionTypes.REFRESH_TOKEN_FULFILLED:
            return __assign(__assign({}, state), { refreshTokenRequestPending: false, refreshTokenRequestFulfilled: true, refreshTokenRequestError: null, userAuthorized: true, loginUserRequestFulfilled: true, user: action.payload.user, headers: __assign(__assign({}, state.headers), { Authorization: "Bearer " + action.payload.tokens.access_token }) });
        case AuthenticationActionTypes.REFRESH_TOKEN_REJECTED:
            return __assign(__assign({}, state), { refreshTokenRequestPending: false, refreshTokenRequestFulfilled: false, refreshTokenRequestError: action.payload });
        default:
            return state;
    }
}
