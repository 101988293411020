import React from 'react';
import './SingleSpeechBubble.scss';
// TODO: move to ui library
var SingleSpeechBubble = function (_a) {
    var featureText = _a.featureText, benefitText = _a.benefitText;
    return (React.createElement("div", { className: "single-speech-bubble" },
        React.createElement("div", { className: "single-speech-bubble__text" },
            featureText && React.createElement("span", { className: "single-speech-bubble__feature-text" },
                featureText,
                " "),
            benefitText)));
};
export default SingleSpeechBubble;
