import React from 'react';
import { useHistory } from 'react-router';
import { CrossIcon } from '@lumera/ts-lumera-ui';
import Modal from '../Modal/Modal';
import './SignedUpModal.scss';
var SignedUpModal = function (_a) {
    var resetSignup = _a.resetSignup;
    var history = useHistory();
    return (React.createElement(Modal, { aboveNav: true, lightBackground: true },
        React.createElement("div", { className: "modal-content" },
            React.createElement("div", { className: "modal-content__top" },
                React.createElement("div", { className: "modal-content__top__header" }, "Thanks for signing up!"),
                React.createElement("div", { className: "modal-content__top__header__icon-container", role: "button", onClick: function () {
                        resetSignup();
                        history.push('/');
                    } },
                    React.createElement(CrossIcon, null))),
            React.createElement("div", { className: "modal-content__paragraph" }, "Check your email to verify your account and then continue to login."))));
};
export default SignedUpModal;
