import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { AssessmentActionTypes, } from './types';
import { submitQuestionAnswerFulfilled, submitQuestionAnswerRejected, getAssessmentResourcesError, getAssessmentResourcesFulfilled, getAllQuestionnaireSummariesError, getAllQuestionnaireSummariesFulfilled, } from './actions';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
import { QuestionnaireSummary } from '../../models/QuestionnaireSummary';
var submitQuestionAnswerEpic = function (action$, state$) {
    return action$.pipe(ofType(AssessmentActionTypes.SUBMIT_QUESTION_ANSWER_PENDING), switchMap(function () {
        var _a;
        var headers = state$.value.authenticationReducer.headers;
        var userId = state$.value.authenticationReducer.user
            ? state$.value.authenticationReducer.user.id
            : (_a = state$.value.authenticationReducer.tempUser) === null || _a === void 0 ? void 0 : _a.id;
        var _b = state$.value.assessmentReducer, selectedAnswers = _b.selectedAnswers, currentQuestionnaire = _b.currentQuestionnaire;
        var body = {
            userId: userId,
            answerIds: selectedAnswers,
            questionnaireId: currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.id,
        };
        return ajax
            .post(process.env.USER_BACKEND_URL + "/assessment/answer-question", body, headers)
            .pipe(map(function (response) {
            return submitQuestionAnswerFulfilled(response.response.questionnaireAssessment);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(submitQuestionAnswerRejected(error));
        }));
    }));
};
var getAssessmentResourcesEpic = function (action$, state$) {
    return action$.pipe(ofType(AssessmentActionTypes.GET_ASSESSMENT_RESOURCES_PENDING), switchMap(function (_a) {
        var _b;
        var payload = _a.payload;
        var userId = state$.value.authenticationReducer.user
            ? state$.value.authenticationReducer.user.id
            : (_b = state$.value.authenticationReducer.tempUser) === null || _b === void 0 ? void 0 : _b.id;
        return ajax
            .get(process.env.USER_BACKEND_URL + "/assessment/get-assessment-resources/?" +
            ("questionnaireId=" + payload + "&") +
            ("userId=" + userId))
            .pipe(map(function (response) {
            return getAssessmentResourcesFulfilled(response.response.questionnaireAssessment);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getAssessmentResourcesError(error));
        }));
    }));
};
var getAllQuestionnaireSummariesEpic = function (action$, state$) {
    return action$.pipe(ofType(AssessmentActionTypes.GET_ALL_QUESTIONNAIRE_SUMMARIES_PENDING), switchMap(function () {
        var _a;
        var userId = state$.value.authenticationReducer.user
            ? state$.value.authenticationReducer.user.id
            : (_a = state$.value.authenticationReducer.tempUser) === null || _a === void 0 ? void 0 : _a.id;
        return ajax.get(process.env.USER_BACKEND_URL + "/assessment?userId=" + userId).pipe(map(function (response) {
            return getAllQuestionnaireSummariesFulfilled(response.response.questionnaires.map(function (questionnaire) { return new QuestionnaireSummary(questionnaire); }));
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getAllQuestionnaireSummariesError(error));
        }));
    }));
};
export default combineEpics(
// resetAssessmentReducerAfterLoginOrLogoutEpic,
// resetAsssessmentReducerEpic,
submitQuestionAnswerEpic, getAssessmentResourcesEpic, getAllQuestionnaireSummariesEpic);
