import moment from 'moment';
import { of, empty, timer } from 'rxjs';
import { switchMap, map, retryWhen, catchError, debounceTime, mergeMap, tap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { AuthenticationActionTypes, } from './types';
import { createUserFulfilled, createUserRejected, authorizeUserFulfilled, authorizeUserRejected, loginUserFulfilled, loginUserRejected, logoutUserPending, logoutUserFulfilled, logoutUserRejected, refreshTokenFulfilled, refreshTokenRejected, refreshTokenPending, setLogoutTimeComplete, signupUserFulfilled, signupUserRejected, } from './actions';
import { User } from '../../models/User';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
import history from '../history';
var createUserEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.CREATE_USER_PENDING), switchMap(function () {
        return ajax.post(process.env.USER_BACKEND_URL + "/user-authentication/create-user").pipe(map(function (response) {
            return createUserFulfilled(new User(response.response));
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(createUserRejected(error));
        }));
    }));
};
var authorizeUserEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.AUTHORIZE_USER_PENDING), switchMap(function (_a) {
        var payload = _a.payload;
        return ajax
            .post(process.env.USER_BACKEND_URL + "/user-authentication/auth", { password: payload })
            .pipe(map(function (response) {
            return authorizeUserFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(authorizeUserRejected(error));
        }));
    }));
};
var refreshTokenEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.REFRESH_TOKEN_PENDING), switchMap(function () {
        return ajax.post(process.env.USER_BACKEND_URL + "/user-authentication/refresh").pipe(map(function (response) {
            return refreshTokenFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410, 401] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(refreshTokenRejected(error));
        }));
    }));
};
var signupUserEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.SIGNUP_USER_PENDING), switchMap(function (_a) {
        var payload = _a.payload;
        return ajax.post(process.env.USER_BACKEND_URL + "/user-authentication/signup", payload).pipe(map(function (response) {
            return signupUserFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 403, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(signupUserRejected(error));
        }));
    }));
};
var loginUserEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.LOGIN_USER_PENDING), switchMap(function (_a) {
        var payload = _a.payload;
        return ajax.post(process.env.USER_BACKEND_URL + "/user-authentication/login", payload).pipe(map(function (response) {
            return loginUserFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 403, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(loginUserRejected(error));
        }));
    }));
};
var logoutUserEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.LOGOUT_USER_PENDING), switchMap(function () {
        return ajax.post(process.env.USER_BACKEND_URL + "/user-authentication/logout").pipe(map(function () { return logoutUserFulfilled(); }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(logoutUserRejected(error));
        }), tap(function () { return history.push('/'); }));
    }));
};
export var setLogoutTimeEpic = function (action$, state$) {
    return action$.pipe(ofType(AuthenticationActionTypes.SET_LOGOUT_TIME_INIT), debounceTime(3000), switchMap(function () {
        var _a = state$.value.authenticationReducer, user = _a.user, logoutTime = _a.logoutTime;
        if (user) {
            if (logoutTime && moment().isSameOrAfter(logoutTime)) {
                console.log('logging out within set epic', logoutTime);
                return of(logoutUserPending());
            }
            return of(setLogoutTimeComplete(moment().add(15, 'minutes')));
        }
        return empty();
    }));
};
export var checkLogoutTimeEpic = function (action$, state$) {
    return action$.pipe(ofType(AuthenticationActionTypes.SET_LOGOUT_TIME_INIT, AuthenticationActionTypes.SET_LOGOUT_TIME_COMPLETE, AuthenticationActionTypes.LOGOUT_USER_FULFILLED), switchMap(function (action) {
        if (action.type === AuthenticationActionTypes.SET_LOGOUT_TIME_COMPLETE) {
            var _a = state$.value.authenticationReducer, logoutTime_1 = _a.logoutTime, logoutUserRequestFulfilled_1 = _a.logoutUserRequestFulfilled;
            return timer(30000, 30000).pipe(mergeMap(function () {
                if (moment().isSameOrAfter(logoutTime_1) && !logoutUserRequestFulfilled_1) {
                    console.log('logging out within check epic', logoutTime_1);
                    return of(logoutUserPending());
                }
                return empty();
            }));
        }
        return empty();
    }));
};
export var requestNewTokenEpic = function (action$) {
    return action$.pipe(ofType(AuthenticationActionTypes.LOGIN_USER_FULFILLED, AuthenticationActionTypes.REFRESH_TOKEN_FULFILLED), switchMap(function () {
        return timer(840000).pipe(map(function () { return refreshTokenPending(); }));
    }));
};
export default combineEpics(createUserEpic, authorizeUserEpic, signupUserEpic, loginUserEpic, logoutUserEpic, refreshTokenEpic, setLogoutTimeEpic, checkLogoutTimeEpic, requestNewTokenEpic);
