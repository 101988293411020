import { AssessmentActionTypes, } from './types';
export var submitQuestionAnswerPending = function () { return ({
    type: AssessmentActionTypes.SUBMIT_QUESTION_ANSWER_PENDING,
}); };
export var submitQuestionAnswerFulfilled = function (payload) { return ({
    type: AssessmentActionTypes.SUBMIT_QUESTION_ANSWER_FULFILLED,
    payload: payload,
}); };
export var submitQuestionAnswerRejected = function (error) { return ({
    type: AssessmentActionTypes.SUBMIT_QUESTION_ANSWER_REJECTED,
    payload: error,
}); };
export var setAssessmentStarted = function (payload) { return ({
    type: AssessmentActionTypes.SET_ASSESSMENT_STARTED,
    payload: payload,
}); };
export var setCurrentQuestion = function (payload) { return ({
    type: AssessmentActionTypes.SET_CURRENT_QUESTION,
    payload: payload,
}); };
export var selectAnswer = function (payload) { return ({
    type: AssessmentActionTypes.SELECT_ANSWER,
    payload: payload,
}); };
export var deselectAnswer = function (payload) { return ({
    type: AssessmentActionTypes.DESELECT_ANSWER,
    payload: payload,
}); };
export var resetAssessmentReducer = function () { return ({
    type: AssessmentActionTypes.RESET_ASSESSMENT_REDUCER,
}); };
export var getAssessmentResourcesPending = function (questionnaireId) { return ({
    type: AssessmentActionTypes.GET_ASSESSMENT_RESOURCES_PENDING,
    payload: questionnaireId,
}); };
export var getAssessmentResourcesFulfilled = function (payload) { return ({
    type: AssessmentActionTypes.GET_ASSESSMENT_RESOURCES_FULFILLED,
    payload: payload,
}); };
export var getAssessmentResourcesError = function (error) { return ({
    type: AssessmentActionTypes.GET_ASSESSMENT_RESOURCES_REJECTED,
    payload: error,
}); };
export var getAllQuestionnaireSummariesPending = function () { return ({
    type: AssessmentActionTypes.GET_ALL_QUESTIONNAIRE_SUMMARIES_PENDING,
}); };
export var getAllQuestionnaireSummariesFulfilled = function (payload) { return ({
    type: AssessmentActionTypes.GET_ALL_QUESTIONNAIRE_SUMMARIES_FULFILLED,
    payload: payload,
}); };
export var getAllQuestionnaireSummariesError = function (error) { return ({
    type: AssessmentActionTypes.GET_ALL_QUESTIONNAIRE_SUMMARIES_REJECTED,
    payload: error,
}); };
export var setCurrentQuestionnaireSummary = function (payload) { return ({
    type: AssessmentActionTypes.SET_CURRENT_QUESTIONNAIRE_SUMMARY,
    payload: payload,
}); };
export var setThemeComplete = function (payload) { return ({
    type: AssessmentActionTypes.SET_THEME_COMPLETE,
    payload: payload,
}); };
