import React from 'react';
import { useHistory } from 'react-router';
import CrossIcon from '../../svgs/CrossIcon';
import './DeleteAccountModal.scss';
var DeleteAccountModal = function (_a) {
    var setDeleteAccountModalShowing = _a.setDeleteAccountModalShowing, deleteAccountPending = _a.deleteAccountPending;
    var history = useHistory();
    return (React.createElement("div", { className: "delete-account-modal" },
        React.createElement("div", { className: "delete-account-modal__header" },
            "Delete account?",
            React.createElement(CrossIcon, { onClick: function () { return setDeleteAccountModalShowing(false); } })),
        React.createElement("div", { className: "delete-account-modal__editable-fields" }, "Once deleted, we\u2019ll delete all your information and stop contacting you. We\u2019ll continue to work on improving Adam and hopefully see you soon."),
        React.createElement("div", { className: "delete-account-modal__actions" },
            React.createElement("button", { type: "submit", disabled: true, className: "delete-account-modal__actions__save-button", onClick: function () {
                    deleteAccountPending();
                    history.push('/');
                } }, "Yes, Delete"))));
};
export default DeleteAccountModal;
