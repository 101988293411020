import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Catalogue from './Catalogue';
import { getProductCataloguePending } from '../../store/productCatalogue/actions';
var mapStateToProps = function (state) { return ({
    products: state.productCatalogueReducer.products,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        getProductCataloguePending: getProductCataloguePending,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
