import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga';
import RecommendationsBanner from '../../components/RecommendationsBanner/RecommendationsBanner';
import RecommendationCard from '../../components/RecommendationCard/RecommendationCard';
import ZeroRecommendationsModal from '../../components/ZeroRecommendationsModal/ZeroRecommendationsModal';
import CrossIcon from '../../svgs/CrossIcon';
import LeftArrow from '../../svgs/LeftArrow';
import './Recommendations.scss';
import indexToString from '../../utils/indexToString';
var Recommendations = function (_a) {
    var recommendations = _a.recommendations, getRecommendationsRequestPending = _a.getRecommendationsRequestPending, user = _a.user, getRecommendationsPending = _a.getRecommendationsPending, refreshTokenRequestPending = _a.refreshTokenRequestPending, tempUser = _a.tempUser;
    var history = useHistory();
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        if (user || tempUser) {
            getRecommendationsPending();
        }
    }, [user, tempUser]);
    if ((!user && refreshTokenRequestPending) || getRecommendationsRequestPending) {
        return React.createElement("h1", null, "loading");
    }
    return (React.createElement("div", { className: "recommendations-page" },
        React.createElement("div", { className: "recommendations-page__nav-container" },
            React.createElement("div", { className: "recommendations-page__nav-container__back-container__button", role: "button", onClick: function () { return history.go(-1); } },
                React.createElement(LeftArrow, null),
                "Back"),
            React.createElement("div", { className: "recommendations-page__nav-container__menu-container", role: "button", onClick: function () { return history.push('/'); } },
                React.createElement(CrossIcon, null),
                "Exit")),
        recommendations.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "feedback-wrapper" },
                React.createElement("div", { className: "feedback-wrapper__text" }, "We'd love to hear your thoughts on Adam so far."),
                React.createElement("div", { className: "feedback-wrapper__feedback-button", role: "button", onClick: function () {
                        return window.open('https://docs.google.com/forms/d/e/1FAIpQLSeWgAfS8S1kzeysJUgbexkV-5WFBBIr6Rm706Vuw8_rPlGpJQ/viewform');
                    }, onKeyPress: function () { return console.log('key pressed'); }, tabIndex: 0 }, "leave feedback")),
            React.createElement(RecommendationsBanner, { numberOfRecommendations: recommendations.length }),
            React.createElement("div", { className: "recommendations-page__recommendations-list" },
                recommendations.map(function (recommendation, index) { return (React.createElement(Fragment, { key: index },
                    React.createElement("div", { className: "recommendations-page__recommendations-list__recommendation-title" },
                        "Here's your ",
                        React.createElement("strong", null, indexToString(index)),
                        " recommendation"),
                    React.createElement(RecommendationCard, { key: recommendation.product.id, recommendation: recommendation, onClick: function () { return history.push("/profile/" + recommendation.product.id); } }))); }),
                React.createElement("div", { className: "feedback-wrapper" },
                    React.createElement("div", { className: "feedback-wrapper__text" }, "We'd love to hear your thoughts on Adam so far."),
                    React.createElement("div", { className: "feedback-wrapper__feedback-button", role: "button", onClick: function () {
                            return window.open('https://docs.google.com/forms/d/e/1FAIpQLSeWgAfS8S1kzeysJUgbexkV-5WFBBIr6Rm706Vuw8_rPlGpJQ/viewform');
                        }, onKeyPress: function () { return console.log('key pressed'); }, tabIndex: 0 }, "leave feedback"))))) : (React.createElement(ZeroRecommendationsModal, null))));
};
export default Recommendations;
