import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setAssessmentStarted } from '../../store/assessment/actions';
import { authorizeUserPending, loginUserPending, logoutUserPending, } from '../../store/authentication/actions';
import Landing from './Landing';
var mapStateToProps = function (state) { return ({
    assessmentInProgress: state.assessmentReducer.assessmentInProgress,
    authorizeUserRequestPending: state.authenticationReducer.authorizeUserRequestPending,
    authorizeUserRequestError: state.authenticationReducer.authorizeUserRequestError,
    userAuthorized: state.authenticationReducer.userAuthorized,
    loginUserRequestPending: state.authenticationReducer.loginUserRequestPending,
    loginUserRequestFulfilled: state.authenticationReducer.loginUserRequestFulfilled,
    loginUserRequestError: state.authenticationReducer.loginUserRequestError,
    user: state.authenticationReducer.user,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        setAssessmentStarted: setAssessmentStarted,
        authorizeUserPending: authorizeUserPending,
        loginUserPending: loginUserPending,
        logoutUserPending: logoutUserPending,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
