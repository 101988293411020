import { RecommendationsActionTypes, } from './types';
console.log("*** recommendations : actions.ts ***");
export var getRecommendationsPending = function () { return ({
    type: RecommendationsActionTypes.GET_RECOMMENDATIONS_PENDING,
}); };
export var getRecommendationsFulfilled = function (payload) { return ({
    type: RecommendationsActionTypes.GET_RECOMMENDATIONS_FULFILLED,
    payload: payload,
}); };
export var getRecommendationsRejected = function (error) { return ({
    type: RecommendationsActionTypes.GET_RECOMMENDATIONS_REJECTED,
    payload: error,
}); };
