import { AuthenticationActionTypes, } from './types';
export var loginUserPending = function (payload) { return ({
    type: AuthenticationActionTypes.LOGIN_USER_PENDING,
    payload: payload,
}); };
export var loginUserFulfilled = function (payload) { return ({
    type: AuthenticationActionTypes.LOGIN_USER_FULFILLED,
    payload: payload,
}); };
export var loginUserRejected = function (error) { return ({
    type: AuthenticationActionTypes.LOGIN_USER_REJECTED,
    payload: error,
}); };
export var signupUserPending = function (payload) { return ({
    type: AuthenticationActionTypes.SIGNUP_USER_PENDING,
    payload: payload,
}); };
export var signupUserFulfilled = function (payload) { return ({
    type: AuthenticationActionTypes.SIGNUP_USER_FULFILLED,
    payload: payload,
}); };
export var signupUserRejected = function (any) { return ({
    type: AuthenticationActionTypes.SIGNUP_USER_REJECTED,
    payload: any,
}); };
export var logoutUserPending = function () { return ({
    type: AuthenticationActionTypes.LOGOUT_USER_PENDING,
}); };
export var logoutUserFulfilled = function () { return ({
    type: AuthenticationActionTypes.LOGOUT_USER_FULFILLED,
}); };
export var logoutUserRejected = function (error) { return ({
    type: AuthenticationActionTypes.LOGOUT_USER_REJECTED,
    payload: error,
}); };
export var setLogoutTimeInit = function () { return ({
    type: AuthenticationActionTypes.SET_LOGOUT_TIME_INIT,
}); };
export var setLogoutTimeComplete = function (payload) { return ({
    type: AuthenticationActionTypes.SET_LOGOUT_TIME_COMPLETE,
    payload: payload,
}); };
export var createUserPending = function () { return ({
    type: AuthenticationActionTypes.CREATE_USER_PENDING,
}); };
export var createUserFulfilled = function (payload) { return ({
    type: AuthenticationActionTypes.CREATE_USER_FULFILLED,
    payload: payload,
}); };
export var createUserRejected = function (error) { return ({
    type: AuthenticationActionTypes.CREATE_USER_REJECTED,
    payload: error,
}); };
export var authorizeUserPending = function (payload) { return ({
    type: AuthenticationActionTypes.AUTHORIZE_USER_PENDING,
    payload: payload,
}); };
export var authorizeUserFulfilled = function (payload) { return ({
    type: AuthenticationActionTypes.AUTHORIZE_USER_FULFILLED,
    payload: payload,
}); };
export var authorizeUserRejected = function (error) { return ({
    type: AuthenticationActionTypes.AUTHORIZE_USER_REJECTED,
    payload: error,
}); };
export var refreshTokenPending = function () { return ({
    type: AuthenticationActionTypes.REFRESH_TOKEN_PENDING,
}); };
export var refreshTokenFulfilled = function (payload) { return ({
    type: AuthenticationActionTypes.REFRESH_TOKEN_FULFILLED,
    payload: payload,
}); };
export var refreshTokenRejected = function (error) { return ({
    type: AuthenticationActionTypes.REFRESH_TOKEN_REJECTED,
    payload: error,
}); };
export var resetSignup = function () { return ({
    type: AuthenticationActionTypes.RESET_SIGNUP,
}); };
export var resetLogin = function () { return ({
    type: AuthenticationActionTypes.RESET_LOGIN,
}); };
