import React from 'react';
import { useHistory } from 'react-router';
import CrossIcon from '../../svgs/CrossIcon';
import './PrivacyModal.scss';
var PrivacyModal = function () {
    var history = useHistory();
    return (React.createElement("div", { className: "privacy-modal" },
        React.createElement("div", { className: "privacy-modal__container" },
            React.createElement("div", { className: "privacy-modal__container__header" },
                "PRIVACY NOTICE",
                React.createElement(CrossIcon, { onClick: function () { return history.push('/'); } })),
            React.createElement("div", { className: "privacy-modal__container__content" },
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Introduction"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "When we say we are committed to protecting your privacy, it\u2019s not a throwaway line, we really do mean it.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "In essence, we avoid asking for and storing any information which could personally identify you."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Who are we?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "Lumera Health Limited and Alzheimer Scotland and are joint controllers for the provision of the service called ADAM.",
                    React.createElement("br", null),
                    "Lumera Health Limited is a private company that has been provisioned by the Scottish Government and Alzheimer Scotland to deliver the Adam service. Lumera Health is subject to the same restrictions on privacy, data processing and information security as per comparable internally-provided services for Alzheimer Scotland. In providing this service Lumera Health does not have any access whatsoever to the information stored on other systems within Alzheimer Scotland for your benefit."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Why do we collect data?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "First and foremost, we collect data from you in order to provide the best possible product/service recommendations. The information you provide helps us better match your specific needs with the features of the product."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Do I have to tell you my name?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "No. We do not request your name or contact details at any time in your usage of ADAM. Your personal details are not stored."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Am I completely anonymous ?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "We would love to say that you are completely anonymous in using ADAM, but that is not strictly true. For example, we use a number of third party service providers, such as google and netlify, in the delivery of ADAM. These services typically log the IP address of the user which can be used to provide an indication of the device used for accessing the internet. We cannot disable that feature. Virtually every website that you visit on the internet will be able to see your IP address. However, IP address is not a reliable indicator as IP addresses are often allocated dynamically by your internet service provider and thus can change frequently.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Moreover, neither google nor netlify provide us with access to their store of IP addresses and we do not make any explicit calls to store your IP address from within ADAM itself.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "As part of the questionnaire, we do request information on your personal circumstances; however, the questionnaire does not request information that is personally identifiable, and this data is not stored with your IP address.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "We do request that visitors to the site provide us with information on their postal town (we never request your full postal code in order to preserve privacy further),",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Note that the postal town information is again stored separately and not associated with any data provided within the questionnaire.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Note also that there is no user profile function within ADAM, and therefore you will not be able to store your answers for future reference.."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "What data do we store?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "We store the following information:",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "(a) High level information on your location (ie we request the first half of your postcode - this allows us to identify your postal town so we have a better understanding of where our users reside. We do not request your name, age, or full address and have no means of identifying you (other than knowing your postal town)",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "(b) Data about your usage of the ADAM website \u2013 for example, pages visited, products recommended, etc. But we do only store this information in aggregate form as we do not personally identify you",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "(c) Data related to your opinions \u2013 such as your views on the recommendations provided by ADAM if you choose to complete a user survey (again, not personally identifiable)"),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "What do we do with the information you provide?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "First and foremost, we use this information to improve the service that we provide you. We want to know where our users are based so that we could, for example, organise related events (eg a tech demo session) which might be of interest to users in that area."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Phone calls about ADAM "),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "You will never receive a call relating to your usage of ADAM, unless you personally have expressly requested a call from Alzheimer Scotland. Note that ADAM is entirely free, so at no point in time will you ever be asked for money to use ADAM. We want to state this point as sadly, there are bogus callers, and we want to make sure you can confidently reject any and all such requests for payment to use ADAM. If you ever do receive such a call, please call Alzheimer Scotland on 0131 243 1453 to report your concerns."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Privacy Notice updates"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "We will be updating our Privacy Notice occasionally and this will be published on the website. The current policy (version 0.1) is effective from 1 February 2022."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Links to other websites"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" }, "Our website may contain links to other websites aimed at providing you with relevant information to help the patient. We do not have any control over the content and management of external websites. Once you leave our site, we cannot be responsible for the protection and privacy of any information that you provide while visiting such sites(and are therefore not governed by this Privacy notice). If you do visit linked sites, we would strongly recommend that you exercise caution and look at any related Privacy Notes on the sites in question."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "Controlling your personal information"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "We do not store any personally identifiable data on you in this version of ADAM.",
                    React.createElement("br", null),
                    "If you have any concerns about the information that is being stored, you can write to Alzheimer Scotland Head Office, 160 Dundee Street, Edinburgh, EH11 1DQ, or call us at 0131 243 1453."),
                React.createElement("div", { className: "privacy-modal__container__content__header" }, "What is a Privacy Notice?"),
                React.createElement("p", { className: "privacy-modal__container__content__paragraph" },
                    "A Privacy Notice (also known as a Fair Processing Notice) tells you about the information that we ask for and hold about you, what we do with it, how we look after this data, and who it is shared with.",
                    React.createElement("br", null),
                    "The Privacy Notice helps us be open and transparent about the way in which your information is processed in order to deliver the underpinning service.")))));
};
export default PrivacyModal;
