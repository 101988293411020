var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var Questionnaire = /** @class */ (function () {
    function Questionnaire(questionnaire) {
        this.id = questionnaire.id;
        this.name = questionnaire.name;
        this.description = questionnaire.description;
        this.questions = questionnaire.questions;
    }
    Questionnaire.prototype.getFirstQuestion = function () {
        return this.questions.find(function (question) { return question.isFirst; });
    };
    Questionnaire.prototype.getQuestionById = function (id) {
        return this.questions.find(function (question) { return question.id === id; });
    };
    Questionnaire.prototype.getQuestionByNeoId = function (neoId) {
        return this.questions.find(function (question) { return question.neoId === neoId; });
    };
    Questionnaire.prototype.getGivenAnswerIds = function () {
        var givenAnswerIds = this.questions.reduce(function (acc, question) {
            var _a;
            var givenAnswersToQuestion = ((_a = question.answers) === null || _a === void 0 ? void 0 : _a.filter(function (answer) { return answer.answered; }).map(function (answer) { return answer.id; })) || [];
            return __spreadArrays(acc, givenAnswersToQuestion);
        }, []);
        return givenAnswerIds;
    };
    Questionnaire.prototype.findIndex = function (question) {
        return this.questions.indexOf(question) + 1;
    };
    return Questionnaire;
}());
export { Questionnaire };
