import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import ProductImages from '../../components/ProductImages';
import ProductDescription from '../../components/ProductDescription/ProductDescription';
import ProductSpecs from '../../components/ProductSpecs/ProductSpecs';
import LeftArrow from '../../svgs/LeftArrow';
import ProductTitledSection from '../../components/ProductTitledSection/ProductTitledSection';
// TODO: move to lumera ui
import SingleSpeechBubble from '../../components/SingleSpeechBubble/SingleSpeechBubble';
import DoubleSpeechBubble from '../../components/DoubleSpeechBubble/DoubleSpeechBubble';
import ReviewBubble from '../../components/ReviewBubble/ReviewBubble';
import './ProductProfile.scss';
var ProductProfile = function (_a) {
    var dialogue = _a.dialogue, getProductProfilePending = _a.getProductProfilePending, getProductProfileRequestPending = _a.getProductProfileRequestPending, history = _a.history, otherUses = _a.otherUses, product = _a.product, refreshTokenRequestPending = _a.refreshTokenRequestPending, resetProductProfile = _a.resetProductProfile, user = _a.user;
    var _b = useParams().productId, productId = _b === void 0 ? '' : _b;
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        if (!productId) {
            history.push('/catalogue');
        }
        if (!refreshTokenRequestPending) {
            getProductProfilePending(productId);
        }
        return function () {
            resetProductProfile();
        };
    }, [history.location, refreshTokenRequestPending]);
    if ((!user && refreshTokenRequestPending) || getProductProfileRequestPending) {
        return React.createElement("h1", null, "loading");
    }
    function getReviewElements(revData, reqdIndex) {
        var delim = "~END~";
        var reviewElements = [];
        while (revData.includes(delim)) {
            var delimPosition = revData.indexOf(delim);
            reviewElements.push(revData.slice(0, delimPosition));
            revData = revData.slice(delimPosition + delim.length);
        }
        if (revData.length > 0) {
            reviewElements.push(revData);
        }
        if (reviewElements.length >= reqdIndex) {
            return reviewElements[reqdIndex];
        }
        else {
            if (reqdIndex === 4) {
                // return default image placeholder
                return "https://adam-image-bucket.s3.eu-west-2.amazonaws.com/a-new-placeholder-img-0.png";
            }
            else {
                return "Index out of bounds.";
            }
        }
    }
    function reviewExists(reviewData) {
        return ((getReviewElements(reviewData, 0) + getReviewElements(reviewData, 1)).length > 0);
    }
    function anyReviewsExist() {
        if (product) {
            return (reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[0]) || reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[1]) || reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[2])
                || reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[3]) || reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[4]) || reviewExists(product === null || product === void 0 ? void 0 : product.reviewData[5]));
        }
        else {
            return false;
        }
    }
    return (React.createElement("div", { className: "product-profile-page" },
        React.createElement("div", { className: "back-container" },
            React.createElement("div", { className: "back-container__button", role: "button", onClick: function () { return history.go(-1); } },
                React.createElement(LeftArrow, null),
                "Back")),
        React.createElement(ProductImages, { product: product, modalHashRoute: "#images" }),
        React.createElement(ProductDescription, { product: product }),
        React.createElement(ProductSpecs, { product: product }),
        dialogue.length > 0 && (React.createElement(ProductTitledSection, { title: "Why this might work for you" }, dialogue.map(function (dialogueItem) { return (React.createElement(DoubleSpeechBubble, { key: dialogueItem.adamText, adamText: dialogueItem.adamText, userText: dialogueItem.userText })); }))),
        React.createElement(ProductTitledSection, { title: "Other uses" }, otherUses.map(function (otherUse) { return (React.createElement(SingleSpeechBubble, { key: otherUse.featureText, featureText: otherUse.featureText, benefitText: otherUse.benefitText })); })),
        React.createElement(ProductTitledSection, { title: "Good to know" }, product === null || product === void 0 ? void 0 : product.goodToKnows.map(function (goodToKnow) {
            return goodToKnow !== '' && React.createElement(SingleSpeechBubble, { key: goodToKnow, benefitText: goodToKnow });
        })),
        anyReviewsExist() && (React.createElement(ProductTitledSection, { title: "Reviews" }, product === null || product === void 0 ? void 0 : product.reviewData.map(function (reviewData) {
            return (getReviewElements(reviewData, 0) + getReviewElements(reviewData, 1)) !== '' && React.createElement(ReviewBubble, { key: reviewData, compositeReviewString: reviewData, reviewProp: getReviewElements });
        })))));
};
export default ProductProfile;
