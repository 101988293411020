var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ajax } from 'rxjs/ajax';
export var get = function (url, headers) {
    return ajax({
        url: url,
        method: 'GET',
        headers: __assign({}, headers),
        withCredentials: true,
        crossDomain: true,
    });
};
export var getNoCredentials = function (url, headers) {
    return ajax({
        url: url,
        method: 'GET',
        headers: __assign({}, headers),
    });
};
export var post = function (url, body, headers) {
    var newAjax = ajax({
        url: url,
        body: body,
        method: 'POST',
        headers: __assign({}, headers),
        withCredentials: true,
        crossDomain: true,
    });
    return newAjax;
};
export var put = function (url, body, headers) {
    return ajax({
        url: url,
        body: body,
        method: 'PUT',
        headers: __assign({}, headers),
        withCredentials: true,
        crossDomain: true,
    });
};
export var del = function (url, body, headers) {
    return ajax({
        url: url,
        body: body,
        method: 'DELETE',
        headers: __assign({}, headers),
        withCredentials: true,
        crossDomain: true,
    });
};
