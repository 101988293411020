import React, { useState } from 'react';
import { GoBackIcon } from '@lumera/ts-lumera-ui';
import Modal from '../Modal/Modal';
import './ProductImages.scss';
var ProductImages = function (_a) {
    var product = _a.product, history = _a.history, modalHashRoute = _a.modalHashRoute;
    var _b = useState(0), productIndex = _b[0], setProductIndex = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-images", onClick: function () { return history.push(modalHashRoute); } }, product === null || product === void 0 ? void 0 :
            product.imageURLs.slice(0, 3).map(function (imageURL, i) { return (React.createElement("div", { key: imageURL, style: { backgroundImage: "url('" + imageURL + "')" }, role: "presentation", className: "product-images__image product-images__image--" + i })); }),
            React.createElement("div", { className: "product-images__image-number" },
                "1 / ", product === null || product === void 0 ? void 0 :
                product.imageURLs.length),
            React.createElement("div", { className: "product-images__button" }, "Show all photos")),
        history.location.hash === modalHashRoute && (React.createElement(Modal, null,
            React.createElement("div", { className: "carousel" },
                React.createElement("div", { className: "carousel__container" },
                    React.createElement("img", { className: "carousel__container__item", src: product === null || product === void 0 ? void 0 : product.imageURLs[productIndex], key: productIndex, alt: product === null || product === void 0 ? void 0 : product.imageAltStrings[productIndex] })),
                React.createElement("div", { className: "carousel__controls" },
                    React.createElement("div", { className: "carousel__controls__button carousel__controls__button--previous", role: "button", onClick: function () {
                            var _a;
                            return setProductIndex(productIndex === 0 ? ((_a = product === null || product === void 0 ? void 0 : product.imageURLs.length) !== null && _a !== void 0 ? _a : 0) - 1 : productIndex - 1);
                        } },
                        React.createElement(GoBackIcon, null)),
                    React.createElement("div", { className: "carousel__controls__image-description" }, product === null || product === void 0 ? void 0 : product.imageAltStrings[productIndex]),
                    React.createElement("div", { className: "carousel__controls__button carousel__controls__button--next", role: "button", onClick: function () {
                            var _a;
                            return setProductIndex(productIndex >= ((_a = product === null || product === void 0 ? void 0 : product.imageURLs.length) !== null && _a !== void 0 ? _a : 0) - 1 ? 0 : productIndex + 1);
                        } },
                        React.createElement(GoBackIcon, null))))))));
};
export default ProductImages;
