import React from 'react';
import CrossIcon from '../../svgs/CrossIcon';
import './CategoryToggleItem.scss';
var CategoryToggleItem = function (_a) {
    var category = _a.category, onClick = _a.onClick, _b = _a.selected, selected = _b === void 0 ? false : _b;
    return (React.createElement("div", { className: selected ? 'category-toggle-item category-toggle-item--selected' : 'category-toggle-item', onClick: onClick },
        category,
        selected && React.createElement(CrossIcon, { fill: "#FFFFFF" })));
};
export default CategoryToggleItem;
