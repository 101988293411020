import { FAQsActionTypes, } from './types';
export var getFAQsPending = function () { return ({
    type: FAQsActionTypes.GET_FAQS_PENDING,
}); };
export var getFAQsFulfilled = function (faqs) { return ({
    type: FAQsActionTypes.GET_FAQS_FULFILLED,
    payload: faqs,
}); };
export var getFAQsRejected = function (error) { return ({
    type: FAQsActionTypes.GET_FAQS_REJECTED,
    payload: error,
}); };
