import React from 'react';
import { useHistory } from 'react-router';
import { CrossIcon } from '@lumera/ts-lumera-ui';
import Modal from '../Modal/Modal';
import './MPowerModal.scss';
var MPowerModal = function (_a) {
    var resetMPower = _a.resetMPower;
    var history = useHistory();
    return (React.createElement(Modal, { aboveNav: true, lightBackground: true },
        React.createElement("div", { className: "modal-content" },
            React.createElement("div", { className: "modal-content__top" },
                React.createElement("div", { className: "modal-content__top__header" }, "Thanks for completing those questions!"),
                React.createElement("div", { className: "modal-content__top__header__icon-container", role: "button", onClick: function () {
                        resetMPower();
                        history.push('/assessment');
                    } },
                    React.createElement(CrossIcon, null))),
            React.createElement("div", { className: "modal-content__paragraph" }, "\u00A0"))));
};
export default MPowerModal;
