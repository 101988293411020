import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CtaButton } from '@lumera/ts-lumera-ui';
import Magnifier from '../../svgs/Magnifier';
import Home from '../../svgs/Home';
import QuestionMark from '../../svgs/QuestionMark';
import AdamLogo from '../../svgs/AdamLogo';
import './Nav.scss';
var NavBar = function (_a) {
    var user = _a.user;
    var _b = useState(window.innerWidth), width = _b[0], setWidth = _b[1];
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    });
    var tablet = width <= 703;
    var history = useHistory();
    var pathName = history.location.pathname.slice(1);
    var hideNav = pathName.includes('profile') || pathName.includes('assessment');
    var hideMobileNav = pathName.includes('catalogue') || pathName.includes('faqs');
    // const [modalOpen, setModalOpen] = useState(false)
    return (React.createElement(React.Fragment, null,
        tablet && !user && !hideMobileNav && !hideNav && (React.createElement("div", { className: "top-nav" },
            React.createElement("div", { className: "top-nav__adam-logo", onClick: function () { return history.push('/'); } },
                React.createElement(AdamLogo, null),
                "Adam"),
            React.createElement(CtaButton, { text: "Get your recommendations", onClick: function () { return history.push('#MPowerDataCheck'); } }))),
        React.createElement("nav", { className: hideNav ? 'nav nav--hide' : 'nav' },
            React.createElement("div", { className: "adam-logo", onClick: function () { return history.push('/'); } },
                React.createElement(AdamLogo, null),
                "Adam"),
            React.createElement("div", { className: "nav-items" },
                React.createElement("div", { className: pathName === '' || pathName === 'dashboard'
                        ? 'nav-items__item nav-items__item--selected'
                        : 'nav-items__item', onClick: function () { return history.push('/'); } },
                    React.createElement(Home, null),
                    "Home"),
                React.createElement("div", { className: pathName === 'catalogue'
                        ? 'nav-items__item nav-items__item--selected'
                        : 'nav-items__item', onClick: function () { return history.push('/catalogue'); } },
                    React.createElement(Magnifier, null),
                    "Browse"),
                React.createElement("div", { className: pathName === 'faqs' ? 'nav-items__item nav-items__item--selected' : 'nav-items__item', onClick: function () { return history.push('/faqs'); } },
                    React.createElement(QuestionMark, null),
                    "FAQ")),
            !user ? (React.createElement(CtaButton, { text: "Get your recommendations", onClick: function () { return history.push('#MPowerDataCheck'); } })) : (React.createElement("div", { className: "nav-items__spacer" })))));
};
export default NavBar;
