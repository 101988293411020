import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Assessment from './Assessment';
import { setAssessmentStarted, setCurrentQuestion, submitQuestionAnswerPending, selectAnswer, deselectAnswer, getAssessmentResourcesPending, setThemeComplete, } from '../../store/assessment/actions';
import { createUserPending } from '../../store/authentication/actions';
var mapStateToProps = function (state) { return ({
    user: state.authenticationReducer.user,
    refreshTokenRequestPending: state.authenticationReducer.refreshTokenRequestPending,
    selectedAnswers: state.assessmentReducer.selectedAnswers,
    submitQuestionAnswerRequestPending: state.assessmentReducer.submitQuestionAnswerRequestPending,
    questionnaires: state.assessmentReducer.questionnaires,
    getAssessmentResourcesRequestFulfilled: state.assessmentReducer.getAssessmentResourcesRequestFulfilled,
    currentQuestionnaire: state.assessmentReducer.currentQuestionnaire,
    assessmentInProgress: state.assessmentReducer.assessmentInProgress,
    tempUser: state.authenticationReducer.tempUser,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        createUserPending: createUserPending,
        deselectAnswer: deselectAnswer,
        getAssessmentResourcesPending: getAssessmentResourcesPending,
        selectAnswer: selectAnswer,
        setAssessmentStarted: setAssessmentStarted,
        setCurrentQuestion: setCurrentQuestion,
        submitQuestionAnswerPending: submitQuestionAnswerPending,
        setThemeComplete: setThemeComplete,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
