import { SettingsActionTypes, } from './types';
export var updateUserNamePending = function (payload) { return ({
    type: SettingsActionTypes.UPDATE_USER_NAME_PENDING,
    payload: payload,
}); };
export var updateUserNameFulfilled = function (payload) { return ({
    type: SettingsActionTypes.UPDATE_USER_NAME_FULFILLED,
    payload: payload,
}); };
export var updateUserNameRejected = function (error) { return ({
    type: SettingsActionTypes.UPDATE_USER_NAME_REJECTED,
    payload: error,
}); };
export var changeEmailPending = function (payload) { return ({
    type: SettingsActionTypes.CHANGE_EMAIL_PENDING,
    payload: payload,
}); };
export var changeEmailFulfilled = function (payload) { return ({
    type: SettingsActionTypes.CHANGE_EMAIL_FULFILLED,
    payload: payload,
}); };
export var changeEmailRejected = function (error) { return ({
    type: SettingsActionTypes.CHANGE_EMAIL_REJECTED,
    payload: error,
}); };
export var changePasswordPending = function (payload) { return ({
    type: SettingsActionTypes.CHANGE_PASSWORD_PENDING,
    payload: payload,
}); };
export var changePasswordFulfilled = function (payload) { return ({
    type: SettingsActionTypes.CHANGE_PASSWORD_FULFILLED,
    payload: payload,
}); };
export var changePasswordRejected = function (error) { return ({
    type: SettingsActionTypes.CHANGE_PASSWORD_REJECTED,
    payload: error,
}); };
export var deleteAccountPending = function () { return ({
    type: SettingsActionTypes.DELETE_ACCOUNT_PENDING,
}); };
export var deleteAccountFulfilled = function (payload) { return ({
    type: SettingsActionTypes.DELETE_ACCOUNT_FULFILLED,
    payload: payload,
}); };
export var deleteAccountRejected = function (error) { return ({
    type: SettingsActionTypes.DELETE_ACCOUNT_REJECTED,
    payload: error,
}); };
