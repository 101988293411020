// import React, { FC, useEffect, useState } from 'react'
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useHistory, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
// import AccessCodeModal from '../../components/AccessCodeModal/AccessCodeModal'
import AdamLogo from '../../svgs/AdamLogo';
// import MPowerModal from '../../components/MPowerModal/MPowerModal'
import './Landing.scss';
var Landing = function (_a) {
    var 
    // userAuthorized,
    // authorizeUserRequestError,
    // authorizeUserRequestPending,
    // authorizeUserPending,
    loginUserRequestFulfilled = _a.loginUserRequestFulfilled, logoutUserPending = _a.logoutUserPending, user = _a.user;
    var history = useHistory();
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    }, []);
    // const [modalOpen, setModalOpen] = useState(false)
    if (user)
        return React.createElement(Redirect, { to: "/dashboard" });
    // if (!userAuthorized) {
    //   return (
    //     <AccessCodeModal
    //       authorizeUserRequestError={authorizeUserRequestError}
    //       authorizeUserRequestPending={authorizeUserRequestPending}
    //       history={history}
    //       authorizeUserPending={authorizeUserPending}
    //     />
    //   )
    // }0
    return (React.createElement("main", { className: "landing" },
        React.createElement("div", { className: "top-banner" },
            React.createElement("div", { className: "top-banner__inner-container" },
                React.createElement("div", { className: "top-banner__text-container" },
                    React.createElement("h2", { className: "info-header" }, "Find the technology you need."),
                    React.createElement("p", { className: "info-paragraph" }, "For people living with long-term conditions; focusing on dementia, Parkinsons disease, vision impairment and hearing loss.")),
                React.createElement("div", { className: "css-video-container" },
                    React.createElement(ReactPlayer, { url: "https://vimeo.com/741824873/5f048d196d", controls: true })))),
        React.createElement("div", { className: "meet-adam" },
            React.createElement("div", { className: "meet-adam__inner-container" },
                React.createElement("div", { className: "meet-adam__text-container" },
                    React.createElement("h3", { className: "info-mini-header" }, "MEET ADAM"),
                    React.createElement("h2", { className: "info-header" }, "Helping you find technology products matched to your needs."),
                    React.createElement("p", { className: "info-paragraph" }, "Based on unbiased, trusted recommendations from people with lived experience of long-term conditions."),
                    React.createElement("p", { className: "info-paragraph info-paragraph--second" }, "This is a free service funded by Alzheimer Scotland and the Scottish Government.")),
                React.createElement("img", { src: "/images/3-phone-location-tablet.png", alt: "", className: "meet-adam__image" }))),
        React.createElement("div", { className: "trust-in-adam" },
            React.createElement("div", { className: "trust-in-adam__inner-container" },
                React.createElement("img", { src: "/images/4-trust-images.png", alt: "", className: "trust-in-adam__image" }),
                React.createElement("div", { className: "trust-in-adam__text-container" },
                    React.createElement("h3", { className: "info-mini-header" }, "TRUST IN ADAM"),
                    React.createElement("h2", { className: "info-header" }, "All the products have been tried and tested by people living with long-term conditions; focusing on dementia, Parkinson\u2019s disease, vision impairment and hearing loss."),
                    React.createElement("p", { className: "info-paragraph" },
                        "We don\u2019t sell anything through this site, but all the products are available from retailers or on the high street. Help with costs may be available\u2014you can find out on our",
                        ' ',
                        React.createElement("span", { className: "span-highlight span-highlight--small", onClick: function () { return history.push('/faqs'); } }, "frequently asked questions"),
                        ' ',
                        "page.")))),
        React.createElement("div", { className: "how-adam-works" },
            React.createElement("div", { className: "how-adam-works__inner-container" },
                React.createElement("div", { className: "how-adam-works__text-container" },
                    React.createElement("h2", { className: "info-mini-header" }, "HOW ADAM WORKS"),
                    React.createElement("h3", { className: "info-header" }, "Answer some questions about what you would like help with"),
                    React.createElement("p", { className: "info-paragraph" }, "Our experienced team has found that if we get to know you better, our recommendations are more likely to meet your expectations and match your needs."),
                    React.createElement("h3", { className: "info-header second" }, "Receive recommendations tailored to your needs and preferences"),
                    React.createElement("p", { className: "info-paragraph" }, "Adam looks at everything that is available and what other people like you have found useful. Adam will guide you through the questionnaire and you can start, take a break and come back whenever you like.")),
                React.createElement("img", { src: "/images/5-signposting.png", alt: "", className: "how-adam-works__image" }))),
        React.createElement("div", { className: "photo-reel" },
            React.createElement("img", { src: "/images/6-three-images.png", alt: "", className: "photo-reel__images" })),
        React.createElement("div", { className: "someone-like-you" },
            React.createElement("div", { className: "someone-like-you__inner-container" },
                React.createElement("h2", { className: "info-mini-header" }, "SOMEONE LIKE YOU"),
                React.createElement("h3", { className: "info-header info-header--center" }, "What real people say about using tech"),
                React.createElement("div", { className: "someone-like-you__testimonials" },
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--video" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--john" }, "BE REALISTIC IN YOUR GOALS"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-container" },
                            React.createElement(ReactPlayer, { url: "https://vimeo.com/742647690", controls: true, "max-height": "250px" })),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-details-container" },
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "June"),
                                " talks about her uncle Peter, and his use of technology for entertainment."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--video" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--sheila" }, "DONT FEAR USING TECH"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-container" },
                            React.createElement(ReactPlayer, { url: "https://vimeo.com/742647273", controls: true, "max-height": "250px" })),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-details-container" },
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Kay and Tom"),
                                " talk about some of the initial challenges they had in finding the right technology."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--video" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--kay" }, "USING TECH AWAY FROM HOME"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-container" },
                            React.createElement(ReactPlayer, { url: "https://vimeo.com/742646509", controls: true, "max-height": "250px" })),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-details-container" },
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Danny"),
                                " describes his personal journey in using tech to help with day to day tasks."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--video" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--ian" }, "EMBEDDING THE TECH IS KEY"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-container" },
                            React.createElement(ReactPlayer, { url: "https://vimeo.com/741824873/5f048d196d", controls: true, "max-height": "250px" })),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__video-details-container" },
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Adam"),
                                " explains how tech can help and how to find the right tech.")))))),
        React.createElement("div", { className: "someone-like-you" },
            React.createElement("div", { className: "someone-like-you__inner-container" },
                React.createElement("h2", { className: "info-mini-header" }, "SOMEONE LIKE YOU"),
                React.createElement("h3", { className: "info-header info-header--center" }, "What real people say about Adam"),
                React.createElement("div", { className: "someone-like-you__testimonials" },
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--john" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--john" }, "ENCOURAGED ABOUT NEXT STEPS"),
                        React.createElement("p", { className: "someone-like-you__testimonial-card__quote" }, "\u201CThere's a host of tech products out there and this is pointing you in the right direction.\u201D"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__person-details-container" },
                            React.createElement("img", { src: "/images/john.png", alt: "", className: "someone-like-you__testimonial-card__avatar" }),
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "John, 61"),
                                " is the main carer for his sister with Vascular Dementia."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--sheila" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--sheila" }, "REASSURED THAT HELP IS OUT THERE"),
                        React.createElement("p", { className: "someone-like-you__testimonial-card__quote" }, "\u201CI've been looking all over the internet to try and find things and it can take hours. This is an awful lot easier.\u201D"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__person-details-container" },
                            React.createElement("img", { src: "/images/sheila.png", alt: "", className: "someone-like-you__testimonial-card__avatar" }),
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Sheila, 64"),
                                " cares for her husband Neil, 68 who has Parkinson's."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--kay" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--kay" }, "OPTIMISTIC ABOUT THE FUTURE"),
                        React.createElement("p", { className: "someone-like-you__testimonial-card__quote" }, "\u201CWe don't want to know the future, but we both want to plan for it as best we can, to be ready for whatever is ahead.\u201D"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__person-details-container" },
                            React.createElement("img", { src: "/images/kay.png", alt: "", className: "someone-like-you__testimonial-card__avatar" }),
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Kay's husband"),
                                " was diagnosed with Frontotemporal Dementia 3 years ago."))),
                    React.createElement("div", { className: "someone-like-you__testimonial-card someone-like-you__testimonial-card--ian" },
                        React.createElement("h4", { className: "someone-like-you__testimonial-card__mini-header someone-like-you__testimonial-card__mini-header--ian" }, "CONFIDENT ABOUT WHAT CHOICES TO MAKE"),
                        React.createElement("p", { className: "someone-like-you__testimonial-card__quote" }, "\u201CIf someone says I need something for my husband, I could say let's go on and see if we can find something to help.\u201D"),
                        React.createElement("div", { className: "someone-like-you__testimonial-card__person-details-container" },
                            React.createElement("img", { src: "/images/ian.png", alt: "", className: "someone-like-you__testimonial-card__avatar" }),
                            React.createElement("p", { className: "someone-like-you__testimonial-card__person-details" },
                                React.createElement("span", { className: "name-highlight" }, "Ian, 58"),
                                " cares for his wife, Wilma, 61, and is a volunteer with Alzheimer's Scotland.")))))),
        React.createElement("div", { className: "entry-points-description" },
            React.createElement("div", { className: "entry-points-description__inner-container" },
                React.createElement("div", { className: "entry-points-description__container" },
                    React.createElement("h2", { className: "info-header" },
                        "If you just want to have a look at the products we have, you can go directly to the",
                        ' ',
                        React.createElement("span", { className: "span-highlight span-highlight--large", onClick: function () { return history.push('/catalogue'); } }, "explore products"),
                        ' ',
                        "page."),
                    React.createElement("h2", { className: "info-header" },
                        "For Adam to give you the best results, we suggest that you get recommendations matched to your needs by using",
                        ' ',
                        React.createElement("span", { className: "span-highlight span-highlight--large", onClick: function () { return history.push('/assessment'); } }, "our questionnaire."))))),
        React.createElement("div", { className: "call-to-action" },
            React.createElement("div", { className: "call-to-action__inner-container" },
                React.createElement("div", { className: "call-to-action__container" },
                    React.createElement("h2", { className: "info-header" }, "Try it now!"),
                    React.createElement("div", { className: "call-to-action__button", role: "button", onClick: function () { return history.push('#MPowerDataCheck'); } }, "Get your recommendations")))),
        React.createElement("div", { className: "feedback-wrapper" },
            React.createElement("div", { className: "feedback-wrapper__text" }, "We'd love to hear your thoughts on Adam so far."),
            React.createElement("div", { className: "feedback-wrapper__feedback-button", role: "button", onClick: function () {
                    return window.open('https://docs.google.com/forms/d/e/1FAIpQLSeWgAfS8S1kzeysJUgbexkV-5WFBBIr6Rm706Vuw8_rPlGpJQ/viewform');
                }, onKeyPress: function () { return console.log('key pressed'); }, tabIndex: 0 }, "leave feedback")),
        React.createElement("div", { className: "footer" },
            React.createElement("div", { className: "footer__links" },
                React.createElement("div", { onClick: function () { return history.push('/catalogue'); }, className: "footer__link" }, "Browse"),
                React.createElement("div", { onClick: function () { return history.push('/faqs'); }, className: "footer__link" }, "FAQ"),
                React.createElement(AdamLogo, null),
                React.createElement("div", { onClick: function () { return history.push('#privacy'); }, className: "footer__link" }, "Privacy")),
            React.createElement("img", { src: "/images/footer-illustrations.png", alt: "footer decorations", className: "footer__illustrations" }))));
};
export default Landing;
