var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState } from 'react';
import { ImageSelect, ProductTitle } from '@lumera/ts-lumera-ui';
import indexToAbc from '../../utils/indexToAbc';
import './ImageSelectContainer.scss';
var ImageSelectContainer = function (_a) {
    var currentQuestion = _a.currentQuestion, answers = _a.answers;
    var _b = useState([]), selectedOptions = _b[0], setSelected = _b[1];
    return (React.createElement("div", { className: "image-select-container" },
        React.createElement(ProductTitle, { title: currentQuestion.text[0], subheader: currentQuestion.info }),
        currentQuestion.text[1] && (React.createElement("h2", { className: "scale-question-container__sub-heading" }, currentQuestion.text[1])),
        React.createElement("div", { className: "image-select-container__image-grid" }, answers &&
            answers.map(function (answer, index) { return (React.createElement(ImageSelect, { onChange: function () {
                    if (selectedOptions.includes(answer.id)) {
                        var updatedOptions = selectedOptions.filter(function (option) { return option !== answer.id; });
                        setSelected(updatedOptions);
                    }
                    else {
                        setSelected(__spreadArrays(selectedOptions, [answer.id]));
                    }
                }, checked: selectedOptions.includes(answer.id), id: answer.id, required: false, name: answer.text, option: answer.text, key: answer.id, imgUrl: answer.imgUrl || '', notSelected: !selectedOptions.includes(answer.id) && selectedOptions.length >= 0, letter: indexToAbc(index) })); }))));
};
export default ImageSelectContainer;
