export var MPowerActionTypes;
(function (MPowerActionTypes) {
    MPowerActionTypes["MPOWER_DATA_PENDING"] = "mpower/MPOWER_DATA_PENDING";
    MPowerActionTypes["MPOWER_DATA_FULFILLED"] = "mpower/MPOWER_DATA_FULFILLED";
    MPowerActionTypes["MPOWER_DATA_REJECTED"] = "mpower/MPOWER_DATA_REJECTED";
    MPowerActionTypes["RESET_MPOWER"] = "mpower/RESET_MPOWER";
    MPowerActionTypes["REFRESH_TOKEN_PENDING"] = "mpower/REFRESH_TOKEN_PENDING";
    MPowerActionTypes["REFRESH_TOKEN_FULFILLED"] = "mpower/REFRESH_TOKEN_FULFILLED";
    MPowerActionTypes["REFRESH_TOKEN_REJECTED"] = "mpower/REFRESH_TOKEN_REJECTED";
    MPowerActionTypes["CREATE_MPOWER_PENDING"] = "mpower/CREATE_MPOWER_PENDING";
    MPowerActionTypes["CREATE_MPOWER_FULFILLED"] = "mpower/CREATE_MPOWER_FULFILLED";
    MPowerActionTypes["CREATE_MPOWER_REJECTED"] = "mpower/CREATE_MPOWER_REJECTED";
})(MPowerActionTypes || (MPowerActionTypes = {}));
