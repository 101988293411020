var Product = /** @class */ (function () {
    function Product(product) {
        this.id = product.id;
        this.name = product.name;
        this.imageURLs = product.imageURLs || [''];
        this.imageAltStrings = product.imageAltStrings || [''];
        this.longDescription = product.longDescription;
        this.shortDescription = product.shortDescription;
        this.goodToKnows = product.goodToKnows || [''];
        this.brand = product.brand;
        this.price = product.price;
        this.link = product.link;
        this.bullet1 = product.bullet1;
        this.bullet2 = product.bullet2;
        this.easeOfUseRating = product.easeOfUseRating;
        this.easeOfUseDescription = product.easeOfUseDescription;
        this.minSetupTime = product.minSetupTime;
        this.maxSetupTime = product.maxSetupTime;
        this.setupTimeDescription = product.setupTimeDescription;
        this.whereToBuy = product.whereToBuy;
        this.categories = product.categories;
        this.reviewData = product.reviewData || [''];
    }
    Product.prototype.getPrice = function () {
        return "\u00A3" + parseInt(this.price, 10) / 100;
    };
    Product.prototype.getPrettyEaseOfUseRating = function () {
        var stringArray = ['', 'Easy', 'Easy to medium', 'Medium', 'Medium to hard', 'Hard'];
        return stringArray[this.easeOfUseRating];
    };
    Product.prototype.getSetupTime = function () {
        return this.minSetupTime + "-" + this.maxSetupTime + " minutes";
    };
    return Product;
}());
export { Product };
