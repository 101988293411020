import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { MPowerActionTypes, } from './types';
import { 
// createMPowerFulfilled,
// createMPowerRejected,
// refreshTokenFulfilled,
// refreshTokenRejected,
// refreshTokenPending,
mPowerDataFulfilled, mPowerDataRejected, } from './actions';
// import { MPowerRecord } from '../../models/MPowerRecord'
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
// const createMPowerEpic = (
//   action$: ActionsObservable<CreateMPowerActions>
// ): Observable<CreateMPowerReturnActions> =>
//   action$.pipe(
//     ofType<CreateMPowerActions>(MPowerActionTypes.CREATE_MPOWER_PENDING),
//     switchMap(
//       (): Observable<CreateMPowerReturnActions> =>
//         ajax.post(`${process.env.USER_BACKEND_URL}/user-authentication/create-user`).pipe(
//           map(
//             (response: AjaxResponse): CreateMPowerFulfilledAction =>
//               createMPowerFulfilled(new MPowerRecord(response.response))
//           ),
//           retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })),
//           catchError(
//             (error: Error): Observable<CreateMPowerRejectedAction> => {
//               Sentry.captureException(error)
//               return of(createMPowerRejected(error))
//             }
//           )
//         )
//     )
//   )
// const refreshTokenEpic = (
//   action$: ActionsObservable<RefreshTokenActions>
// ): Observable<RefreshTokenReturnActions> =>
//   action$.pipe(
//     ofType<RefreshTokenActions>(MPowerActionTypes.REFRESH_TOKEN_PENDING),
//     switchMap(
//       (): Observable<RefreshTokenReturnActions> =>
//         ajax.post(`${process.env.USER_BACKEND_URL}/user-authentication/refresh`).pipe(
//           map(
//             (response: AjaxResponse): RefreshTokenFulfilledAction =>
//               refreshTokenFulfilled(response.response)
//           ),
//           retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410, 401] })),
//           catchError(
//             (error: Error): Observable<RefreshTokenRejectedAction> => {
//               Sentry.captureException(error)
//               return of(refreshTokenRejected(error))
//             }
//           )
//         )
//     )
//   )
var mPowerDataEpic = function (action$) {
    return action$.pipe(ofType(MPowerActionTypes.MPOWER_DATA_PENDING), switchMap(function (_a) {
        var payload = _a.payload;
        return ajax.post(process.env.USER_BACKEND_URL + "/assessment/create-mpower-data", payload).pipe(map(function (response) {
            return mPowerDataFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 403, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(mPowerDataRejected(error));
        }));
    }));
};
// export const requestNewTokenEpic = (
//   action$: ActionsObservable<RefreshTokenActions>
// ): Observable<RefreshTokenActions> =>
//   action$.pipe(
//     ofType<RefreshTokenActions>(
//       MPowerActionTypes.MPOWER_DATA_FULFILLED,
//       MPowerActionTypes.REFRESH_TOKEN_FULFILLED
//     ),
//     switchMap(() => {
//       return timer(840000).pipe(map((): RefreshTokenActions => refreshTokenPending()))
//     })
//   )
export default combineEpics(mPowerDataEpic);
