import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { DropdownSearchInput } from '@lumera/lumera-ui';
import ReactGA from 'react-ga';
import CrossIcon from '../../svgs/CrossIcon';
import LoginHeader from '../../components/LoginHeader/LoginHeader';
import './Login.scss';
var Login = function (_a) {
    var loginUserPending = _a.loginUserPending, loginUserRequestPending = _a.loginUserRequestPending, loginUserRequestError = _a.loginUserRequestError, resetLogin = _a.resetLogin, user = _a.user;
    var history = useHistory();
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var _d = useState(''), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState(false), disabled = _e[0], setDisabled = _e[1];
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    useEffect(function () {
        if (loginUserRequestError) {
            setErrorMessage(loginUserRequestError);
        }
        else {
            setErrorMessage('');
        }
    }, [loginUserRequestError]);
    useEffect(function () {
        if (errorMessage !== '' || email === '' || password === '') {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [email, password, errorMessage]);
    useEffect(function () {
        if (loginUserRequestError) {
            resetLogin();
        }
    }, [email, password]);
    if (user)
        return React.createElement(Redirect, { to: history.location.pathname });
    return (React.createElement("div", { className: "login-modal" },
        React.createElement("div", { className: "login-modal__modal-form" },
            React.createElement("div", { className: "login-modal__modal-form__header" },
                React.createElement("div", { className: "login-modal__modal-form__header__container" },
                    React.createElement(LoginHeader, { text: "Sign up", selected: history.location.hash === '#signup', setView: function () { return history.push('/#signup'); } }),
                    React.createElement(LoginHeader, { text: "Login", selected: history.location.hash === '#login', setView: function () { return history.push('/#login'); } })),
                React.createElement(CrossIcon, { onClick: function () { return history.push(history.location.pathname); } })),
            React.createElement("form", { className: "login-modal__login-form", autoComplete: "off", onSubmit: function (e) { return e.preventDefault(); }, role: "presentation" },
                React.createElement("div", { className: "login-modal__login-form__content" },
                    React.createElement(DropdownSearchInput, { labelText: "EMAIL", value: email, placeholder: "example@email.com", onChange: function (e) { return setEmail(e); }, required: true, name: "email", errorTag: errorMessage }),
                    React.createElement(DropdownSearchInput, { type: "password", labelText: "PASSWORD", value: password, placeholder: "***********", onChange: function (e) { return setPassword(e); }, required: true, name: "password-input", errorTag: errorMessage })),
                React.createElement("div", { className: "login-modal__login-form__bottom" },
                    React.createElement("button", { type: "submit", disabled: disabled || loginUserRequestPending, className: disabled
                            ? 'login-modal__login-form__bottom__login-button login-modal__login-form__bottom__login-button--disabled'
                            : 'login-modal__login-form__bottom__login-button', onClick: function () {
                            return loginUserPending({
                                username: email,
                                password: password,
                            });
                        } }, "Login"))))));
};
export default Login;
