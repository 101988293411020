import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { CtaButton, ProgressBar } from '@lumera/ts-lumera-ui';
import QuestionContainer from '../../components/QuestionContainer/QuestionContainer';
import LeftArrow from '../../svgs/LeftArrow';
import Dots from '../../svgs/Dots';
import './Assessment.scss';
var Assessment = function (_a) {
    var assessmentInProgress = _a.assessmentInProgress, createUserPending = _a.createUserPending, currentQuestionnaire = _a.currentQuestionnaire, deselectAnswer = _a.deselectAnswer, getAssessmentResourcesPending = _a.getAssessmentResourcesPending, getAssessmentResourcesRequestFulfilled = _a.getAssessmentResourcesRequestFulfilled, questionnaires = _a.questionnaires, refreshTokenRequestPending = _a.refreshTokenRequestPending, selectAnswer = _a.selectAnswer, selectedAnswers = _a.selectedAnswers, setAssessmentStarted = _a.setAssessmentStarted, setCurrentQuestion = _a.setCurrentQuestion, submitQuestionAnswerPending = _a.submitQuestionAnswerPending, submitQuestionAnswerRequestPending = _a.submitQuestionAnswerRequestPending, setThemeComplete = _a.setThemeComplete, tempUser = _a.tempUser, user = _a.user;
    var params = useParams();
    var history = useHistory();
    useEffect(function () {
        if (user || tempUser) {
            getAssessmentResourcesPending(params.questionnaireId || questionnaires[0].id);
        }
    }, [params.questionnaireId, user, tempUser]);
    useEffect(function () {
        if (!user && !refreshTokenRequestPending && !tempUser) {
            createUserPending();
        }
    }, [refreshTokenRequestPending]);
    var currentQuestion = params.questionId
        ? currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.getQuestionById(params.questionId) : currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.getFirstQuestion();
    useEffect(function () {
        if (currentQuestion)
            setCurrentQuestion(currentQuestion);
    }, [currentQuestion]);
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        setAssessmentStarted(true);
        return function () {
            setAssessmentStarted(false);
        };
    }, []);
    var nextQuestion = currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.getQuestionByNeoId(currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.next);
    var totalQuestions = (currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.questions.length) || 1;
    var currentQuestionNumber = (currentQuestionnaire === null || currentQuestionnaire === void 0 ? void 0 : currentQuestionnaire.findIndex(currentQuestion)) || 1;
    var progress = Math.round((currentQuestionNumber / totalQuestions) * 100);
    return (React.createElement("div", { className: "assessment-page" },
        React.createElement("div", { className: "assessment-page__nav-container" },
            React.createElement("div", { className: "assessment-page__nav-container__back-container", role: "button", onClick: function () { return history.go(-1); } },
                React.createElement(LeftArrow, null),
                "Back"),
            assessmentInProgress && (React.createElement(React.Fragment, null,
                React.createElement(ProgressBar, { progress: progress }),
                React.createElement("div", { className: "assessment-page__nav-container__back-container", role: "button", onClick: function () { return history.push('/assessment'); } },
                    React.createElement(Dots, null),
                    "Menu")))),
        getAssessmentResourcesRequestFulfilled &&
            currentQuestionnaire &&
            !submitQuestionAnswerRequestPending && (React.createElement("div", { className: "assessment-page__question-page" },
            React.createElement(QuestionContainer, { selectedAnswers: selectedAnswers, selectAnswer: selectAnswer, deselectAnswer: deselectAnswer, currentQuestion: currentQuestion }),
            React.createElement("div", { className: "assessment-page__question-page__button-container" },
                React.createElement(CtaButton, { text: "Continue", onClick: function () {
                        submitQuestionAnswerPending();
                        if (nextQuestion) {
                            history.push("/assessment/theme/" + params.questionnaireId + "/question/" + (nextQuestion === null || nextQuestion === void 0 ? void 0 : nextQuestion.id));
                        }
                        else {
                            history.push('/assessment');
                            setThemeComplete(true);
                        }
                    } }))))));
};
export default Assessment;
