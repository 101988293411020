import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Faqs from './Faqs';
import { getFAQsPending } from '../../store/faqs/actions';
var mapStateToProps = function (state) { return ({
    faqs: state.faqsReducer.faqs,
    getFAQsRequestFulfilled: state.faqsReducer.getFAQsRequestFulfilled,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ getFAQsPending: getFAQsPending }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
