import { combineReducers } from 'redux';
import assessmentReducer from './assessment/reducers';
import authenticationReducer from './authentication/reducers';
import mPowerReducer from './mpower/reducers';
import faqsReducer from './faqs/reducers';
import productCatalogueReducer from './productCatalogue/reducers';
import productProfileReducer from './productProfile/reducers';
import recommendationsReducer from './recommendations/reducers';
var rootReducer = combineReducers({
    assessmentReducer: assessmentReducer,
    authenticationReducer: authenticationReducer,
    mPowerReducer: mPowerReducer,
    faqsReducer: faqsReducer,
    productCatalogueReducer: productCatalogueReducer,
    productProfileReducer: productProfileReducer,
    recommendationsReducer: recommendationsReducer,
});
export default rootReducer;
