import React, { useEffect, useState } from 'react';
import { CatalogueCard } from '@lumera/ts-lumera-ui';
import DoubleSpeechBubble from '../DoubleSpeechBubble/DoubleSpeechBubble';
import './RecommendationCard.scss';
var RecommendationCard = function (_a) {
    var recommendation = _a.recommendation, onClick = _a.onClick;
    var _b = useState(window.innerWidth), width = _b[0], setWidth = _b[1];
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    });
    var tablet = width <= 768;
    var mobile = width <= 530;
    return (React.createElement("div", { className: "recommendation-card", role: "button", onClick: onClick },
        React.createElement("div", { className: tablet
                ? 'recommendation-card__product-preview recommendation-card__product-preview--tablet'
                : 'recommendation-card__product-preview' },
            React.createElement(CatalogueCard, { product: recommendation.product, onClick: onClick, withSpeechBubbles: !!tablet, recommendation: recommendation, oneBubble: !!mobile })),
        !tablet && (React.createElement("div", { className: "recommendation-card__dialogue" }, recommendation.dialogue.slice(0, 3).map(function (dialogueItem) { return (React.createElement(DoubleSpeechBubble, { key: dialogueItem.adamText, adamText: dialogueItem.adamText, userText: dialogueItem.userText })); })))));
};
export default RecommendationCard;
