var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Product } from '../../models/Product';
import { ProductProfileActionTypes } from './types';
var initialState = {
    product: undefined,
    dialogue: [],
    otherUses: [],
    getProductProfileRequestPending: false,
    getProductProfileRequestFulfilled: false,
    getProductProfileRequestError: undefined,
};
export default function productProfileReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ProductProfileActionTypes.GET_PRODUCT_PROFILE_PENDING:
            return __assign(__assign({}, state), { getProductProfileRequestPending: true, getProductProfileRequestFulfilled: false, getProductProfileRequestError: undefined });
        case ProductProfileActionTypes.GET_PRODUCT_PROFILE_FULFILLED: {
            return __assign(__assign({}, state), { getProductProfileRequestPending: false, getProductProfileRequestFulfilled: true, getProductProfileRequestError: undefined, product: new Product(action.payload.product), dialogue: action.payload.dialogue, otherUses: action.payload.otherUses });
        }
        case ProductProfileActionTypes.GET_PRODUCT_PROFILE_REJECTED:
            return __assign(__assign({}, state), { getProductProfileRequestPending: false, getProductProfileRequestFulfilled: false, getProductProfileRequestError: action.payload });
        case ProductProfileActionTypes.SET_CURRENT_PRODUCT:
            return __assign(__assign({}, state), { product: action.payload });
        case ProductProfileActionTypes.RESET_PRODUCT_PROFILE:
            return __assign(__assign({}, state), { product: undefined, getProductProfileRequestFulfilled: false, getProductProfileRequestError: undefined, dialogue: [], otherUses: [], getProductProfileRequestPending: false });
        default:
            return state;
    }
}
