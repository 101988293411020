import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DropdownSearchInput } from '@lumera/lumera-ui';
import CrossIcon from '../../svgs/CrossIcon';
import './UpdateNameForm.scss';
var UpdateNameForm = function (_a) {
    var setUpdateNameFormShowing = _a.setUpdateNameFormShowing, updateUserNamePending = _a.updateUserNamePending;
    var history = useHistory();
    var _b = useState(''), firstName = _b[0], setFirstName = _b[1];
    var _c = useState(''), lastName = _c[0], setLastName = _c[1];
    return (React.createElement("div", { className: "update-name-form" },
        React.createElement("div", { className: "update-name-form__header" },
            "Update name",
            React.createElement(CrossIcon, { onClick: function () { return setUpdateNameFormShowing(false); } })),
        React.createElement("div", { className: "update-name-form__editable-fields" },
            React.createElement(DropdownSearchInput, { type: "text", labelText: "FIRST NAME", value: firstName, placeholder: "first name", onChange: function (e) { return setFirstName(e); }, required: true, name: "first-name-input" }),
            React.createElement(DropdownSearchInput, { type: "text", labelText: "SURNAME", value: lastName, placeholder: "surname", onChange: function (e) { return setLastName(e); }, required: true, name: "last-name-input" })),
        React.createElement("div", { className: "update-name-form__actions" },
            React.createElement("button", { type: "submit", disabled: true, className: 'update-name-form__actions__save-button update-name-form__actions__save-button--disabled', onClick: function () {
                    updateUserNamePending({ firstName: firstName, lastName: lastName });
                    history.push('/');
                } }, "Save"))));
};
export default UpdateNameForm;
