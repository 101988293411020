import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AssessmentLanding from './AssessmentLanding';
import { getAssessmentResourcesPending, getAllQuestionnaireSummariesPending, setCurrentQuestionnaireSummary, setThemeComplete, } from '../../store/assessment/actions';
import { createUserPending } from '../../store/authentication/actions';
var mapStateToProps = function (state) { return ({
    user: state.authenticationReducer.user,
    tempUser: state.authenticationReducer.tempUser,
    refreshTokenRequestPending: state.authenticationReducer.refreshTokenRequestPending,
    questionnaires: state.assessmentReducer.questionnaires,
    getAllQuestionnaireSummariesRequestFulfilled: state.assessmentReducer.getAllQuestionnaireSummariesRequestFulfilled,
    currentQuestionnaireSummary: state.assessmentReducer.currentQuestionnaireSummary,
    currentQuestionnaire: state.assessmentReducer.currentQuestionnaire,
    submitQuestionAnswerRequestPending: state.assessmentReducer.submitQuestionAnswerRequestPending,
    submitQuestionAnswerRequestFulfilled: state.assessmentReducer.submitQuestionAnswerRequestFulfilled,
    themeComplete: state.assessmentReducer.themeComplete,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        createUserPending: createUserPending,
        getAssessmentResourcesPending: getAssessmentResourcesPending,
        getAllQuestionnaireSummariesPending: getAllQuestionnaireSummariesPending,
        setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary,
        setThemeComplete: setThemeComplete,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentLanding);
