var QuestionnaireSummary = /** @class */ (function () {
    function QuestionnaireSummary(questionnaire) {
        this.id = questionnaire.id;
        this.name = questionnaire.name;
        this.description = questionnaire.description;
        this.noOfAnsweredQuestions = questionnaire.noOfAnsweredQuestions;
        this.noOfQuestions = questionnaire.noOfQuestions;
        this.isFirst = questionnaire.isFirst;
        this.next = questionnaire.next;
    }
    QuestionnaireSummary.prototype.getCompletionPercentage = function () {
        return Math.round((this.noOfAnsweredQuestions / this.noOfQuestions) * 100);
    };
    return QuestionnaireSummary;
}());
export { QuestionnaireSummary };
