import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { ProductCatalogueActionTypes, } from './types';
import { getProductCatalogueFulfilled, getProductCatalogueRejected } from './actions';
import { Product } from '../../models/Product';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
var getProductCatalogueEpic = function (action$) {
    return action$.pipe(ofType(ProductCatalogueActionTypes.GET_PRODUCT_CATALOGUE_PENDING), switchMap(function () {
        console.log('Im getting catalogue'); // eslint-disable-line no-console
        return ajax.get(process.env.USER_BACKEND_URL + "/catalogue").pipe(map(function (response) {
            return getProductCatalogueFulfilled(response.response.products.map(function (product) { return new Product(product); }));
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getProductCatalogueRejected(error));
        }));
    }));
};
export default combineEpics(getProductCatalogueEpic);
