import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import ThemeList from '../../components/ThemeList/ThemeList';
import ThemeDescription from '../../components/ThemeDescription/ThemeDescription';
import AssessmentLandingMobile from '../../components/AssessmentLandingMobile/AssessmentLandingMobile';
import ExitModal from '../../components/ExitModal/ExitModal';
import LeftArrow from '../../svgs/LeftArrow';
import CrossIcon from '../../svgs/CrossIcon';
import './AssessmentLanding.scss';
var AssessmentLanding = function (_a) {
    var createUserPending = _a.createUserPending, currentQuestionnaireSummary = _a.currentQuestionnaireSummary, currentQuestionnaire = _a.currentQuestionnaire, getAllQuestionnaireSummariesPending = _a.getAllQuestionnaireSummariesPending, getAllQuestionnaireSummariesRequestFulfilled = _a.getAllQuestionnaireSummariesRequestFulfilled, getAssessmentResourcesPending = _a.getAssessmentResourcesPending, questionnaires = _a.questionnaires, themeComplete = _a.themeComplete, setCurrentQuestionnaireSummary = _a.setCurrentQuestionnaireSummary, setThemeComplete = _a.setThemeComplete, submitQuestionAnswerRequestPending = _a.submitQuestionAnswerRequestPending, submitQuestionAnswerRequestFulfilled = _a.submitQuestionAnswerRequestFulfilled, refreshTokenRequestPending = _a.refreshTokenRequestPending, user = _a.user, tempUser = _a.tempUser;
    var history = useHistory();
    var _b = useState(3), nextIndex = _b[0], setIndex = _b[1];
    var currentSummary = currentQuestionnaire && questionnaires.find(function (q) { return q.id === currentQuestionnaire.id; });
    useEffect(function () {
        if (user || tempUser) {
            getAllQuestionnaireSummariesPending();
        }
        if (currentSummary) {
            setCurrentQuestionnaireSummary(currentSummary);
            var nextQuestionnaireIndex = questionnaires.indexOf(currentSummary) + 1;
            setIndex(nextQuestionnaireIndex);
        }
    }, [user, submitQuestionAnswerRequestFulfilled, tempUser]);
    useEffect(function () {
        if (!user && !refreshTokenRequestPending && !tempUser) {
            createUserPending();
        }
    }, [refreshTokenRequestPending]);
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        return function () {
            setThemeComplete(false);
        };
    }, []);
    var _c = useState(window.innerWidth), width = _c[0], setWidth = _c[1];
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    });
    var tablet = width <= 768;
    var _d = useState(false), menuOpen = _d[0], setMenuOpen = _d[1];
    var _e = useState(false), modalOpen = _e[0], setModalOpen = _e[1];
    return (React.createElement("div", { className: "assessment-page" },
        !tablet && (React.createElement("div", { className: "assessment-page__nav-container" },
            React.createElement("div", { className: "assessment-page__nav-container__back-container", role: "button", onClick: function () { return history.go(-1); } },
                React.createElement(LeftArrow, null),
                "Back"),
            React.createElement("div", { className: "assessment-page__nav-container__back-container", role: "button", onClick: function () { return setModalOpen(true); } },
                React.createElement(CrossIcon, null),
                "Exit"))),
        getAllQuestionnaireSummariesRequestFulfilled && !submitQuestionAnswerRequestPending && (React.createElement("div", { className: "assessment-page__start-container" }, tablet ? (React.createElement(AssessmentLandingMobile, { currentQuestionnaireSummary: currentQuestionnaireSummary, getAssessmentResourcesPending: getAssessmentResourcesPending, menuOpen: menuOpen, nextIndex: nextIndex, questionnaires: questionnaires, themeComplete: themeComplete, setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, setMenuOpen: setMenuOpen, setThemeComplete: setThemeComplete, setModalOpen: setModalOpen, tablet: tablet })) : (React.createElement(React.Fragment, null,
            React.createElement(ThemeList, { questionnaires: questionnaires, setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, currentQuestionnaireSummary: currentQuestionnaireSummary, setThemeComplete: setThemeComplete, setMenuOpen: setMenuOpen }),
            React.createElement(ThemeDescription, { setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, currentQuestionnaireSummary: currentQuestionnaireSummary, questionnaires: questionnaires, getAssessmentResourcesPending: getAssessmentResourcesPending, nextIndex: nextIndex, themeComplete: themeComplete, setThemeComplete: setThemeComplete, setMenuOpen: setMenuOpen, tablet: tablet }))))),
        modalOpen && React.createElement(ExitModal, { setModalOpen: setModalOpen })));
};
export default AssessmentLanding;
