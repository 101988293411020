import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { FAQsActionTypes, } from './types';
import { getFAQsFulfilled, getFAQsRejected } from './actions';
import { FAQQuestion } from '../../models/FAQQuestion';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
var getFAQsEpic = function (action$) {
    return action$.pipe(ofType(FAQsActionTypes.GET_FAQS_PENDING), switchMap(function () {
        return ajax.get(process.env.USER_BACKEND_URL + "/faqs").pipe(map(function (response) {
            return getFAQsFulfilled(response.response.faqs.map(function (faqQuestion) { return new FAQQuestion(faqQuestion); }));
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getFAQsRejected(error));
        }));
    }));
};
export default combineEpics(getFAQsEpic);
