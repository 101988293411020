export var SettingsActionTypes;
(function (SettingsActionTypes) {
    SettingsActionTypes["UPDATE_USER_NAME_PENDING"] = "settings/UPDATE_USER_NAME_PENDING";
    SettingsActionTypes["UPDATE_USER_NAME_FULFILLED"] = "settings/UPDATE_USER_NAME_FULFILLED";
    SettingsActionTypes["UPDATE_USER_NAME_REJECTED"] = "settings/UPDATE_USER_NAME_REJECTED";
    SettingsActionTypes["CHANGE_PASSWORD_PENDING"] = "settings/CHANGE_PASSWORD_PENDING";
    SettingsActionTypes["CHANGE_PASSWORD_FULFILLED"] = "settings/CHANGE_PASSWORD_FULFILLED";
    SettingsActionTypes["CHANGE_PASSWORD_REJECTED"] = "settings/CHANGE_PASSWORD_REJECTED";
    SettingsActionTypes["CHANGE_EMAIL_PENDING"] = "settings/CHANGE_EMAIL_PENDING";
    SettingsActionTypes["CHANGE_EMAIL_FULFILLED"] = "settings/CHANGE_EMAIL_FULFILLED";
    SettingsActionTypes["CHANGE_EMAIL_REJECTED"] = "settings/CHANGE_EMAIL_REJECTED";
    SettingsActionTypes["DELETE_ACCOUNT_PENDING"] = "settings/DELETE_ACCOUNT_PENDING";
    SettingsActionTypes["DELETE_ACCOUNT_FULFILLED"] = "settings/DELETE_ACCOUNT_FULFILLED";
    SettingsActionTypes["DELETE_ACCOUNT_REJECTED"] = "settings/DELETE_ACCOUNT_REJECTED";
})(SettingsActionTypes || (SettingsActionTypes = {}));
