import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga';
import ChangeEmailForm from '../../components/ChangeEmailForm/ChangeEmailForm';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import UpdateNameForm from '../../components/UpdateNameForm/UpdateNameForm';
import DeleteAccountModal from '../../components/DeleteAccountModal/DeleteAccountModal';
import CrossIcon from '../../svgs/CrossIcon';
import RightArrow from '../../svgs/RightArrow';
import './UserSettings.scss';
// TODO: add editable fields
var UserProfile = function (_a) {
    var user = _a.user, logoutUserPending = _a.logoutUserPending, changeEmailPending = _a.changeEmailPending, updateUserNamePending = _a.updateUserNamePending, changePasswordPending = _a.changePasswordPending, deleteAccountPending = _a.deleteAccountPending;
    var history = useHistory();
    var _b = useState(false), updateNameFormShowing = _b[0], setUpdateNameFormShowing = _b[1];
    var _c = useState(false), changeEmailFormShowing = _c[0], setChangeEmailFormShowing = _c[1];
    var _d = useState(false), changePasswordFormShowing = _d[0], setChangePasswordFormShowing = _d[1];
    var _e = useState(false), deleteAccountModalShowing = _e[0], setDeleteAccountModalShowing = _e[1];
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (React.createElement("div", { className: "user-settings-modal" },
        React.createElement("div", { className: "user-settings" },
            updateNameFormShowing && (React.createElement(UpdateNameForm, { setUpdateNameFormShowing: setUpdateNameFormShowing, updateUserNamePending: updateUserNamePending })),
            changeEmailFormShowing && (React.createElement(ChangeEmailForm, { setChangeEmailFormShowing: setChangeEmailFormShowing, changeEmailPending: changeEmailPending })),
            changePasswordFormShowing && (React.createElement(ChangePasswordForm, { setChangePasswordFormShowing: setChangePasswordFormShowing, changePasswordPending: changePasswordPending })),
            deleteAccountModalShowing && (React.createElement(DeleteAccountModal, { setDeleteAccountModalShowing: setDeleteAccountModalShowing, deleteAccountPending: deleteAccountPending })),
            React.createElement("div", { className: "user-settings__header" },
                "Settings",
                React.createElement(CrossIcon, { onClick: function () { return history.push(history.location.pathname); } })),
            React.createElement("div", { className: "user-settings__editable-fields" },
                React.createElement("div", { className: "user-settings__editable-fields__field", onClick: function () {
                        setUpdateNameFormShowing(true);
                    } },
                    React.createElement("div", { className: "left-align" },
                        "Name",
                        React.createElement("div", { className: "user-settings__editable-fields__field__preview" }, (user === null || user === void 0 ? void 0 : user.firstName) + " " + (user === null || user === void 0 ? void 0 : user.lastName))),
                    React.createElement(RightArrow, { fill: '#22313A' })),
                React.createElement("div", { className: "user-settings__editable-fields__field", onClick: function () {
                        setChangeEmailFormShowing(true);
                    } },
                    React.createElement("div", { className: "left-align" },
                        "Email",
                        React.createElement("div", { className: "user-settings__editable-fields__field__preview" }, user === null || user === void 0 ? void 0 : user.email)),
                    React.createElement(RightArrow, { fill: '#22313A' })),
                React.createElement("div", { className: "user-settings__editable-fields__field", onClick: function () {
                        setChangePasswordFormShowing(true);
                    } },
                    React.createElement("div", { className: "left-align" }, "Password"),
                    React.createElement(RightArrow, { fill: '#22313A' }))),
            React.createElement("div", { className: "user-settings__actions" },
                React.createElement("div", { className: "delete-account-button", onClick: function () { return setDeleteAccountModalShowing(true); } }, "Delete account"),
                React.createElement("div", { className: "logout-button", onClick: function () {
                        logoutUserPending();
                        history.push('/');
                    } }, "Logout")))));
};
export default UserProfile;
