var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecommendationsActionTypes } from './types';
var initialState = {
    recommendations: [],
    getRecommendationsRequestPending: false,
    getRecommendationsRequestFulfilled: false,
    getRecommendationsRequestError: null,
};
export default function recommendationsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RecommendationsActionTypes.GET_RECOMMENDATIONS_PENDING:
            return __assign(__assign({}, state), { getRecommendationsRequestPending: true, getRecommendationsRequestFulfilled: false, getRecommendationsRequestError: null });
        case RecommendationsActionTypes.GET_RECOMMENDATIONS_FULFILLED:
            return __assign(__assign({}, state), { getRecommendationsRequestPending: false, getRecommendationsRequestFulfilled: true, getRecommendationsRequestError: null, recommendations: action.payload.recommendations || [] });
        case RecommendationsActionTypes.GET_RECOMMENDATIONS_REJECTED:
            return __assign(__assign({}, state), { getRecommendationsRequestPending: false, getRecommendationsRequestFulfilled: false, getRecommendationsRequestError: action.payload });
        default:
            return state;
    }
}
