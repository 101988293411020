import React from 'react';
import './ProductTitledSection.scss';
// TODO: move to ui library
var ProductTitledSection = function (_a) {
    var title = _a.title, children = _a.children;
    return (React.createElement("div", { className: "titled-section" },
        React.createElement("h3", null, title),
        React.createElement("div", { className: "titled-section__content" }, children)));
};
export default ProductTitledSection;
