export var AssessmentActionTypes;
(function (AssessmentActionTypes) {
    AssessmentActionTypes["GET_ALL_QUESTIONNAIRE_SUMMARIES_PENDING"] = "assessment/GET_ALL_QUESTIONNAIRE_SUMMARIES_PENDING";
    AssessmentActionTypes["GET_ALL_QUESTIONNAIRE_SUMMARIES_FULFILLED"] = "assessment/GET_ALL_QUESTIONNAIRE_SUMMARIES_FULFILLED";
    AssessmentActionTypes["GET_ALL_QUESTIONNAIRE_SUMMARIES_REJECTED"] = "assessment/GET_ALL_QUESTIONNAIRE_SUMMARIES_REJECTED";
    AssessmentActionTypes["GET_ASSESSMENT_RESOURCES_PENDING"] = "assessment/GET_ASSESSMENT_RESOURCES_PENDING";
    AssessmentActionTypes["GET_ASSESSMENT_RESOURCES_FULFILLED"] = "assessment/GET_ASSESSMENT_RESOURCES_FULFILLED";
    AssessmentActionTypes["GET_ASSESSMENT_RESOURCES_REJECTED"] = "assessment/GET_ASSESSMENT_RESOURCES_REJECTED";
    AssessmentActionTypes["SUBMIT_QUESTION_ANSWER_PENDING"] = "assessment/SUBMIT_QUESTION_ANSWER_PENDING";
    AssessmentActionTypes["SUBMIT_QUESTION_ANSWER_FULFILLED"] = "assessment/SUBMIT_QUESTION_ANSWER_FULFILLED";
    AssessmentActionTypes["SUBMIT_QUESTION_ANSWER_REJECTED"] = "assessment/SUBMIT_QUESTION_ANSWER_REJECTED";
    AssessmentActionTypes["SET_ASSESSMENT_STARTED"] = "assessment/SET_ASSESSMENT_STARTED";
    AssessmentActionTypes["SET_CURRENT_QUESTION"] = "assessment/SET_CURRENT_QUESTION";
    AssessmentActionTypes["SELECT_ANSWER"] = "assessment/SELECT_ANSWER";
    AssessmentActionTypes["DESELECT_ANSWER"] = "assessment/DESELECT_ANSWER";
    AssessmentActionTypes["RESET_ASSESSMENT_REDUCER"] = "assessment/RESET_ASSESSMENT_REDUCER";
    AssessmentActionTypes["SET_CURRENT_QUESTIONNAIRE_SUMMARY"] = "assessment/SET_CURRENT_QUESTIONNAIRE_SUMMARY";
    AssessmentActionTypes["SET_THEME_COMPLETE"] = "assessment/SET_THEME_COMPLETE";
})(AssessmentActionTypes || (AssessmentActionTypes = {}));
