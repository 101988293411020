import { throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';
export var genericRetryStrategy = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.maxRetryAttempts, maxRetryAttempts = _c === void 0 ? 3 : _c, _d = _b.scalingDuration, scalingDuration = _d === void 0 ? 1000 : _d, _e = _b.excludedStatusCodes, excludedStatusCodes = _e === void 0 ? [] : _e;
    return function (attempts) {
        return attempts.pipe(mergeMap(function (error, i) {
            var retryAttempt = i + 1;
            //* if maximum number of retries have been met
            //* or response is a status code we don't wish to retry, throw error
            if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(function (e) { return e === error.status; })) {
                return throwError(error);
            }
            // console.log(
            //   `Attempt ${retryAttempt}: retrying in ${retryAttempt *
            //     scalingDuration}ms`
            // );
            //* retry after 1s, 2s, etc...
            return timer(retryAttempt * scalingDuration);
        }), finalize(function () {
            // console.log("We are done!");
        }));
    };
};
export var blah = '';
