import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers.ts';
import history from './history';
import epics from './epics';
import { AuthenticationActionTypes } from './authentication/types';
var composeEnhancers = composeWithDevTools({
    actionsBlacklist: AuthenticationActionTypes.SET_LOGOUT_TIME_INIT,
});
var epicMiddleware = createEpicMiddleware();
var routerStuff = routerMiddleware(history);
var middleware = applyMiddleware(routerStuff, epicMiddleware);
var enhancers = process.env.NODE_ENV === 'production' ? middleware : composeEnhancers(middleware);
var store = createStore(rootReducer, {}, enhancers);
epicMiddleware.run(epics);
export default store;
