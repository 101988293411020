import React from 'react';
import './DoubleSpeechBubble.scss';
var DoubleSpeechBubble = function (_a) {
    var adamText = _a.adamText, userText = _a.userText;
    return (React.createElement("div", { className: "double-speech-bubble" },
        React.createElement("div", { className: "double-speech-bubble__text" },
            React.createElement("div", { className: "double-speech-bubble__header double-speech-bubble__header--user-text" },
                React.createElement("strong", null, "You"),
                " said"),
            React.createElement("div", { className: "double-speech-bubble__user-text" }, userText),
            React.createElement("div", { className: "double-speech-bubble__header double-speech-bubble__header--adam-text" },
                React.createElement("strong", null, "Adam"),
                " says"),
            React.createElement("div", { className: "double-speech-bubble__adam-text" }, adamText))));
};
export default DoubleSpeechBubble;
