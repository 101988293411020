var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { SectionTitle, CatalogueCard, DiversionCard } from '@lumera/ts-lumera-ui';
import CategoryToggleItem from '../../components/CategoryToggleItem/CategoryToggleItem';
import './Catalogue.scss';
var Catalogue = function (_a) {
    var getProductCataloguePending = _a.getProductCataloguePending, products = _a.products;
    var history = useHistory();
    var query = new URLSearchParams(useLocation().search);
    var categories = query.get('categories');
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        getProductCataloguePending();
    }, []);
    var categoryList = [
        'Having a purpose in your day',
        'Having a safe and happy home',
        'Staying connected and in touch',
        'Feeling on top of being a carer',
        'Getting out and about confidently',
        'Looking after yourself and feeling resilient',
        'Having fun and filling the day',
    ];
    var selectedCategoryList = categories ? decodeURI(categories).split(',') : [];
    return (React.createElement("div", { className: "catalogue-page" },
        React.createElement("div", { className: "catalogue-page__product-section" },
            React.createElement(SectionTitle, { boldText: "Discover", text: "what's out there" }),
            React.createElement("div", { className: "catalogue-page__product-section__category-section" },
                React.createElement("div", { className: "catalogue-page__product-section__category-section__header" }, "Select a tag"),
                React.createElement("div", { className: "catalogue-page__product-section__category-section__section" }, categoryList.map(function (category) { return (React.createElement(CategoryToggleItem, { key: category, category: category, selected: selectedCategoryList.includes(category), onClick: function () {
                        var newCategoriesList = selectedCategoryList.includes(category)
                            ? selectedCategoryList.filter(function (c) { return c !== category; })
                            : __spreadArrays(selectedCategoryList, [category]);
                        var queryParam = newCategoriesList.length > 0
                            ? "?categories=" + encodeURI(newCategoriesList.join(','))
                            : '';
                        history.push("" + history.location.pathname + queryParam);
                    } })); }))),
            React.createElement("div", { className: "catalogue-page__product-section__product-grid" },
                products
                    .filter(function (product) {
                    return selectedCategoryList.length > 0
                        ? product.categories.some(function (category) { return selectedCategoryList.includes(category); })
                        : true;
                })
                    .map(function (product) { return (React.createElement(CatalogueCard, { onClick: function () {
                        history.push("/profile/" + product.id);
                    }, key: product.id, product: product })); }),
                React.createElement(DiversionCard, { header: "Any questions?", subHeader: "Click for answers", onClick: function () { return history.push('/faqs'); } })))));
};
export default Catalogue;
