import React from 'react';
import './ProductDescription.scss';
// TODO: move to ui library
var ProductDescription = function (_a) {
    var product = _a.product;
    return (React.createElement("div", { className: "product-description" },
        React.createElement("div", { className: "product-description__inner-container" },
            React.createElement("div", { className: "product-description__inner-container--left-column" },
                React.createElement("h3", null, product === null || product === void 0 ? void 0 : product.brand),
                React.createElement("h2", null, product === null || product === void 0 ? void 0 : product.name)),
            React.createElement("p", null, product === null || product === void 0 ? void 0 : product.longDescription))));
};
export default ProductDescription;
