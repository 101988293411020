import React from 'react';
import { AbcCheckBox, ProductTitle } from '@lumera/ts-lumera-ui';
import indexToAbc from '../../utils/indexToAbc';
import './ScaleContainer.scss';
var ScaleContainer = function (_a) {
    var currentQuestion = _a.currentQuestion, answers = _a.answers, selectAnswer = _a.selectAnswer, deselectAnswer = _a.deselectAnswer, selectedAnswers = _a.selectedAnswers;
    var currentQuestionAnswerId = answers === null || answers === void 0 ? void 0 : answers.map(function (answer) { return answer.id; }).find(function (id) { return selectedAnswers.includes(id); });
    return (React.createElement("div", { className: "scale-question-container" },
        React.createElement(ProductTitle, { title: currentQuestion.text[0], subheader: currentQuestion.info }),
        currentQuestion.text[1] && (React.createElement("h2", { className: "scale-question-container__sub-heading" }, currentQuestion.text[1])),
        React.createElement("div", { className: "scale-question-container__scale-container" }, answers &&
            answers.map(function (answer, index) { return (React.createElement(AbcCheckBox, { onChange: function () {
                    if (currentQuestionAnswerId && answer.id !== currentQuestionAnswerId) {
                        deselectAnswer(currentQuestionAnswerId);
                        selectAnswer(answer.id);
                    }
                    if (!currentQuestionAnswerId) {
                        selectAnswer(answer.id);
                    }
                }, checked: selectedAnswers.includes(answer.id), id: answer.id, required: false, name: answer.text, option: answer.text, key: answer.id, letter: indexToAbc(index) })); }))));
};
export default ScaleContainer;
