import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import MainRoutes from './routes/MainRoutes';
import store from './store';
import { refreshTokenPending, setLogoutTimeInit } from './store/authentication/actions';
import './App.scss';
Sentry.init({
    dsn: 'https://ad946f2ac1c8424786865d93ed256211@o245167.ingest.sentry.io/5320153',
    environment: process.env.NODE_ENV,
});
ReactGA.initialize('UA-178653363-1');
ReactGA.pageview(window.location.pathname + window.location.search);
store.dispatch(refreshTokenPending());
var logoutTimer = function () {
    var resetTimer = function () {
        store.dispatch(setLogoutTimeInit());
    };
    window.onload = resetTimer;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer;
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer;
    document.onscroll = resetTimer;
    document.onkeypress = resetTimer;
};
logoutTimer();
var App = function () {
    return (React.createElement(Provider, { store: store },
        React.createElement(Router, null,
            React.createElement(MainRoutes, null))));
};
export default App;
