import React from 'react';
import './RecommendationsBanner.scss';
// TODO: move to ui library
var RecommendationCard = function (_a) {
    var numberOfRecommendations = _a.numberOfRecommendations;
    return (React.createElement("div", { className: "recommendations-banner" },
        React.createElement("img", { alt: "echo show icon", src: "./images/echo-show-icon.png", className: "recommendations-banner__show-icon" }),
        React.createElement("img", { alt: "light bulb icon", src: "./images/light-bulb-icon.png", className: "recommendations-banner__light-icon" }),
        React.createElement("h2", { className: "recommendations-banner__header" }, "Thanks for helping me get to know you"),
        React.createElement("p", { className: "recommendations-banner__paragraph" }, "I have " + numberOfRecommendations + " recommendation" + (numberOfRecommendations !== 1 ? 's' : '') + " for you, based on what you said you\u2019d like help with. Let me show you.")));
};
export default RecommendationCard;
