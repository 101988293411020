import React from 'react';
import './ProductSpecs.scss';
// TODO: move to ui library
var ProductSpecs = function (_a) {
    var product = _a.product;
    return (React.createElement("div", { className: "product-specs" },
        React.createElement("div", { className: "product-specs__spec" },
            React.createElement("h3", null, "EASE OF USE"),
            React.createElement("h4", null, product === null || product === void 0 ? void 0 : product.getPrettyEaseOfUseRating()),
            React.createElement("p", null, product === null || product === void 0 ? void 0 : product.easeOfUseDescription)),
        React.createElement("div", { className: "product-specs__spec" },
            React.createElement("h3", null, "SET-UP TIME"),
            React.createElement("h4", null, product === null || product === void 0 ? void 0 : product.getSetupTime()),
            React.createElement("p", null, product === null || product === void 0 ? void 0 : product.setupTimeDescription)),
        React.createElement("div", { className: "product-specs__spec" },
            React.createElement("h3", null, "PRICE"),
            React.createElement("h4", null, product === null || product === void 0 ? void 0 : product.getPrice()),
            React.createElement("p", null, product === null || product === void 0 ? void 0 : product.whereToBuy))));
};
export default ProductSpecs;
