import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DropdownSearchInput } from '@lumera/lumera-ui';
import CrossIcon from '../../svgs/CrossIcon';
import './ChangePasswordForm.scss';
var ChangePasswordForm = function (_a) {
    var setChangePasswordFormShowing = _a.setChangePasswordFormShowing, changePasswordPending = _a.changePasswordPending;
    var history = useHistory();
    var _b = useState(''), currentPassword = _b[0], setCurrentPassword = _b[1];
    var _c = useState(''), newPassword = _c[0], setNewPassword = _c[1];
    var _d = useState(''), confirmPassword = _d[0], setConfirmPassword = _d[1];
    return (React.createElement("div", { className: "change-password-form" },
        React.createElement("div", { className: "change-password-form__header" },
            "Change password",
            React.createElement(CrossIcon, { onClick: function () { return setChangePasswordFormShowing(false); } })),
        React.createElement("div", { className: "change-password-form__editable-fields" },
            React.createElement(DropdownSearchInput, { type: "password", labelText: "CURRENT PASSWORD", value: currentPassword, placeholder: "***********", onChange: function (e) { return setCurrentPassword(e); }, required: true, name: "current-password-input" }),
            React.createElement(DropdownSearchInput, { type: "password", labelText: "NEW PASSWORD", value: newPassword, placeholder: "***********", onChange: function (e) { return setNewPassword(e); }, required: true, name: "new-password-input" }),
            React.createElement(DropdownSearchInput, { type: "password", labelText: "CONFIRM PASSWORD", value: confirmPassword, placeholder: "***********", onChange: function (e) { return setConfirmPassword(e); }, required: true, name: "confirm-password-input" })),
        React.createElement("div", { className: "change-password-form__actions" },
            React.createElement("div", { className: "delete-account-button" }, "Forgot password?"),
            React.createElement("button", { type: "submit", disabled: true, className: 'change-password-form__actions__save-button change-password-form__actions__save-button--disabled', onClick: function () {
                    changePasswordPending(newPassword);
                    history.push('/');
                } }, "Save"))));
};
export default ChangePasswordForm;
