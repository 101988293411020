import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Recommendations from './Recommendations';
import { getRecommendationsPending } from '../../store/recommendations/actions';
var mapStateToProps = function (state) { return ({
    user: state.authenticationReducer.user,
    tempUser: state.authenticationReducer.tempUser,
    recommendations: state.recommendationsReducer.recommendations,
    refreshTokenRequestPending: state.authenticationReducer.refreshTokenRequestPending,
    getRecommendationsRequestPending: state.recommendationsReducer.getRecommendationsRequestPending,
    getRecommendationsRequestFulfilled: state.recommendationsReducer.getRecommendationsRequestFulfilled,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        getRecommendationsPending: getRecommendationsPending,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
