import React from 'react';
import { CtaButton } from '@lumera/ts-lumera-ui';
import { useHistory } from 'react-router-dom';
import FinishedTheme from '../FinishedTheme/FinishedTheme';
//import Lock from '../../svgs/Lock'
//import LockBigger from '../../svgs/LockBigger'
import './ThemeDescription.scss';
var ThemeDescription = function (_a) {
    var currentQuestionnaireSummary = _a.currentQuestionnaireSummary, questionnaires = _a.questionnaires, setCurrentQuestionnaireSummary = _a.setCurrentQuestionnaireSummary, nextIndex = _a.nextIndex, themeComplete = _a.themeComplete, setMenuOpen = _a.setMenuOpen, setThemeComplete = _a.setThemeComplete, tablet = _a.tablet;
    var history = useHistory();
    var aboutMeComplete = questionnaires[0].getCompletionPercentage() === 100;
    // const anyQuestionnaireComplete = 
    // questionnaires[0].getCompletionPercentage() === 100
    // || questionnaires[1].getCompletionPercentage() === 100
    // || questionnaires[2].getCompletionPercentage() === 100
    // || questionnaires[3].getCompletionPercentage() === 100
    // || questionnaires[4].getCompletionPercentage() === 100
    // || questionnaires[5].getCompletionPercentage() === 100
    // || questionnaires[6].getCompletionPercentage() === 100
    // || questionnaires[7].getCompletionPercentage() === 100
    var buttonText = function (qsummary) {
        var text = '';
        var questionnaireStarted = qsummary && qsummary.getCompletionPercentage() > 0;
        if (qsummary && questionnaireStarted) {
            text = 'Continue';
        }
        else if (!qsummary) {
            text = 'Next';
        }
        else {
            text = "Let's get started";
        }
        return text;
    };
    return (React.createElement("div", { className: "theme-description-container" }, !themeComplete ? (React.createElement("div", { className: "theme-description-container__description-content" },
        currentQuestionnaireSummary && (React.createElement("img", { src: "./images/theme-icons.png", alt: "theme-icons", className: "theme-description-container__description-content__image" })),
        React.createElement("h3", { className: currentQuestionnaireSummary
                ? 'theme-description-container__description-content__header'
                : 'theme-description-container__description-content__default-header' }, currentQuestionnaireSummary
            ? currentQuestionnaireSummary.description[0]
            : "Hello, I'm Adam, pleased to meet you!"),
        React.createElement("div", { className: "theme-description-container__description-content__paragraph" }, currentQuestionnaireSummary
            ? currentQuestionnaireSummary.description[1]
            : "You may be interested to know that I am Scottish and if you could hear me speak I would have a Glaswegian accent, even though I've spent a lot of time in Edinburgh. I'm a dog lover and I hope to get my own virtual pet one day!"),
        aboutMeComplete ||
            !currentQuestionnaireSummary ||
            currentQuestionnaireSummary.name === 'About Me' ? (React.createElement(CtaButton, { text: buttonText(currentQuestionnaireSummary), onClick: function () {
                var questionnaireToSet = questionnaires === null || questionnaires === void 0 ? void 0 : questionnaires.find(function (q) { return q.name === 'About Me'; });
                if (currentQuestionnaireSummary) {
                    history.push("/assessment/theme/" + (currentQuestionnaireSummary === null || currentQuestionnaireSummary === void 0 ? void 0 : currentQuestionnaireSummary.id));
                }
                else {
                    setCurrentQuestionnaireSummary(questionnaireToSet);
                }
                setMenuOpen(false);
            } })) : (React.createElement("div", { className: "theme-description-container__description-content__mandatory-banner" },
            React.createElement(CtaButton, { text: buttonText(currentQuestionnaireSummary), onClick: function () {
                    // const questionnaireToSet = questionnaires?.find((q) => q.name === 'About Me')
                    // if (currentQuestionnaireSummary) {
                    history.push("/assessment/theme/" + (currentQuestionnaireSummary === null || currentQuestionnaireSummary === void 0 ? void 0 : currentQuestionnaireSummary.id));
                    // } else {
                    //   setCurrentQuestionnaireSummary(questionnaireToSet)
                    // }
                    setMenuOpen(false);
                } }))))) : (React.createElement(FinishedTheme, { currentQuestionnaireSummary: currentQuestionnaireSummary, setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, questionnaires: questionnaires, nextIndex: nextIndex, setThemeComplete: setThemeComplete, setMenuOpen: setMenuOpen, tablet: tablet }))));
};
export default ThemeDescription;
