import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ThemeList from '../ThemeList/ThemeList';
import ThemeDescription from '../ThemeDescription/ThemeDescription';
import LeftArrow from '../../svgs/LeftArrow';
import CrossIcon from '../../svgs/CrossIcon';
import './AssessmentLanding.scss';
var AssessmentLandingMobile = function (_a) {
    var currentQuestionnaireSummary = _a.currentQuestionnaireSummary, getAssessmentResourcesPending = _a.getAssessmentResourcesPending, menuOpen = _a.menuOpen, nextIndex = _a.nextIndex, questionnaires = _a.questionnaires, themeComplete = _a.themeComplete, setCurrentQuestionnaireSummary = _a.setCurrentQuestionnaireSummary, setMenuOpen = _a.setMenuOpen, setModalOpen = _a.setModalOpen, setThemeComplete = _a.setThemeComplete, tablet = _a.tablet;
    var history = useHistory();
    useEffect(function () {
        return function () {
            setMenuOpen(true);
        };
    }, []);
    return (React.createElement("div", { className: "assessment-page-mobile" },
        React.createElement("div", { className: "assessment-page-mobile__nav-container" },
            React.createElement("div", { className: "assessment-page-mobile__nav-container__back-container", role: "button", onClick: function () {
                    if (menuOpen) {
                        history.go(-1);
                    }
                    else {
                        setMenuOpen(true);
                    }
                } },
                React.createElement(LeftArrow, null),
                "Back"),
            React.createElement("div", { className: "assessment-page-mobile__nav-container__back-container", role: "button", onClick: function () { return setModalOpen(true); } },
                React.createElement(CrossIcon, null),
                "Exit")),
        menuOpen && !themeComplete ? (React.createElement(ThemeList, { questionnaires: questionnaires, setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, currentQuestionnaireSummary: currentQuestionnaireSummary, setThemeComplete: setThemeComplete, setMenuOpen: setMenuOpen })) : (React.createElement(ThemeDescription, { setCurrentQuestionnaireSummary: setCurrentQuestionnaireSummary, currentQuestionnaireSummary: currentQuestionnaireSummary, questionnaires: questionnaires, getAssessmentResourcesPending: getAssessmentResourcesPending, nextIndex: nextIndex, themeComplete: themeComplete, setThemeComplete: setThemeComplete, setMenuOpen: setMenuOpen, tablet: tablet }))));
};
export default AssessmentLandingMobile;
