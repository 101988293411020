import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductProfile from './ProductProfile';
import { getProductProfilePending, resetProductProfile } from '../../store/productProfile/actions';
var mapStateToProps = function (state) { return ({
    product: state.productProfileReducer.product,
    dialogue: state.productProfileReducer.dialogue,
    otherUses: state.productProfileReducer.otherUses,
    getProductProfileRequestPending: state.productProfileReducer.getProductProfileRequestPending,
    user: state.authenticationReducer.user,
    refreshTokenRequestPending: state.authenticationReducer.refreshTokenRequestPending,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        getProductProfilePending: getProductProfilePending,
        resetProductProfile: resetProductProfile,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductProfile);
