import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoggedInRoute from './LoggedInRoute';
import HashRoute from './HashRoute';
import AssessmentLanding from '../containers/AssessmentLanding';
import Catalogue from '../containers/Catalogue';
import Dashboard from '../containers/Dashboard';
import Faqs from '../containers/Faqs';
import Login from '../containers/Login';
import Landing from '../containers/Landing';
import NewAssessment from '../containers/Assessment';
import ProductProfile from '../containers/ProductProfile';
import Recommendations from '../containers/Recommendations';
import Signup from '../containers/Signup';
import MPowerDataCheck from '../containers/MPowerDataCheck';
import UserSettings from '../containers/UserSettings';
import PrivacyModal from '../components/PrivacyModal/PrivacyModal';
import Nav from '../components/Nav';
var MainRoutes = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Route, { path: "/" },
            React.createElement(Nav, null)),
        React.createElement(HashRoute, { path: "#user-settings", component: UserSettings }),
        React.createElement(HashRoute, { path: "#login", component: Login }),
        React.createElement(HashRoute, { path: "#signup", component: Signup }),
        React.createElement(HashRoute, { path: "#MPowerDataCheck", component: MPowerDataCheck }),
        React.createElement(HashRoute, { path: "#privacy", component: PrivacyModal }),
        React.createElement(Switch, null,
            React.createElement(PrivateRoute, { path: "/catalogue", component: Catalogue }),
            React.createElement(PrivateRoute, { path: "/faqs", component: Faqs }),
            React.createElement(PrivateRoute, { exact: true, path: "/assessment", component: AssessmentLanding }),
            React.createElement(PrivateRoute, { exact: true, path: "/assessment/theme/:questionnaireId", component: NewAssessment }),
            React.createElement(PrivateRoute, { exact: true, path: "/assessment/theme/:questionnaireId/question/:questionId", component: NewAssessment }),
            React.createElement(PrivateRoute, { path: "/recommendations", component: Recommendations }),
            React.createElement(PrivateRoute, { path: "/profile/:productId", component: ProductProfile }),
            React.createElement(LoggedInRoute, { path: "/dashboard", component: Dashboard }),
            React.createElement(Route, { path: "/", component: Landing }))));
};
export default MainRoutes;
