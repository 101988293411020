import { of } from 'rxjs';
import { switchMap, map, retryWhen, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { ProductProfileActionTypes, } from './types';
import { getProductProfileFulfilled, getProductProfileRejected } from './actions';
import * as ajax from '../../utils/ajax';
import { genericRetryStrategy } from '../../utils/retryWhen.ts';
var getProductProfileEpic = function (action$, state$) {
    return action$.pipe(ofType(ProductProfileActionTypes.GET_PRODUCT_PROFILE_PENDING), switchMap(function (_a) {
        var _b;
        var payload = _a.payload;
        var userId = state$.value.authenticationReducer.user
            ? state$.value.authenticationReducer.user.id
            : (_b = state$.value.authenticationReducer.tempUser) === null || _b === void 0 ? void 0 : _b.id;
        return ajax
            .get(process.env.USER_BACKEND_URL + "/product-profile/" +
            ("?userId=" + userId) +
            ("&productId=" + payload))
            .pipe(map(function (response) {
            return getProductProfileFulfilled(response.response);
        }), retryWhen(genericRetryStrategy({ excludedStatusCodes: [400, 409, 410] })), catchError(function (error) {
            Sentry.captureException(error);
            return of(getProductProfileRejected(error));
        }));
    }));
};
export default combineEpics(getProductProfileEpic);
