import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserSettings from './UserSettings';
import { logoutUserPending } from '../../store/authentication/actions';
import { changeEmailPending, updateUserNamePending, changePasswordPending, deleteAccountPending, } from '../../store/settings/actions';
var mapStateToProps = function (state) { return ({
    user: state.authenticationReducer.user,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        logoutUserPending: logoutUserPending,
        changeEmailPending: changeEmailPending,
        updateUserNamePending: updateUserNamePending,
        changePasswordPending: changePasswordPending,
        deleteAccountPending: deleteAccountPending,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
