import { MPowerActionTypes, } from './types';
export var mPowerDataPending = function (payload) { return ({
    type: MPowerActionTypes.MPOWER_DATA_PENDING,
    payload: payload,
}); };
export var mPowerDataFulfilled = function (payload) { return ({
    type: MPowerActionTypes.MPOWER_DATA_FULFILLED,
    payload: payload,
}); };
export var mPowerDataRejected = function (any) { return ({
    type: MPowerActionTypes.MPOWER_DATA_REJECTED,
    payload: any,
}); };
export var createMPowerPending = function () { return ({
    type: MPowerActionTypes.CREATE_MPOWER_PENDING,
}); };
export var createMPowerFulfilled = function (payload) { return ({
    type: MPowerActionTypes.CREATE_MPOWER_FULFILLED,
    payload: payload,
}); };
export var createMPowerRejected = function (error) { return ({
    type: MPowerActionTypes.CREATE_MPOWER_REJECTED,
    payload: error,
}); };
// export const refreshTokenPending = (): RefreshTokenPendingAction => ({
//   type: MPowerActionTypes.REFRESH_TOKEN_PENDING,
// })
// export const refreshTokenFulfilled = (
//   payload: RefreshTokenFulfilledAction['payload']
// ): RefreshTokenFulfilledAction => ({
//   type: MPowerActionTypes.REFRESH_TOKEN_FULFILLED,
//   payload,
// })
// export const refreshTokenRejected = (error: Error): RefreshTokenRejectedAction => ({
//   type: MPowerActionTypes.REFRESH_TOKEN_REJECTED,
//   payload: error,
// })
export var resetMPower = function () { return ({
    type: MPowerActionTypes.RESET_MPOWER,
}); };
