import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';
// TODO put in ui library
var Modal = function (_a) {
    var children = _a.children, _b = _a.aboveNav, aboveNav = _b === void 0 ? false : _b, _c = _a.lightBackground, lightBackground = _c === void 0 ? false : _c;
    var el = useState(document.createElement('div'))[0];
    useEffect(function () {
        var modalRoot = document.querySelector('body');
        if (modalRoot)
            modalRoot.appendChild(el);
        return function () {
            if (modalRoot)
                modalRoot.removeChild(el);
        };
    }, []);
    return createPortal(React.createElement("div", { className: "modal " + (aboveNav ? 'modal--above-nav' : 'modal--below-nav') + " " + (lightBackground && 'modal--light') }, children), el);
};
export default Modal;
