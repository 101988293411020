import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mPowerDataPending, resetMPower } from '../../store/mpower/actions';
import MPowerDataCheck from './MPowerDataCheck';
var mapStateToProps = function (state) { return ({
    mPowerDataRequestPending: state.mPowerReducer.mPowerDataRequestPending,
    mPowerDataRequestError: state.mPowerReducer.mPowerDataRequestError,
    mPowerDataRequestFulfilled: state.mPowerReducer.mPowerDataRequestFulfilled,
    mPowerRecord: state.mPowerReducer.mPowerRecord,
}); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        mPowerDataPending: mPowerDataPending,
        resetMPower: resetMPower,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(MPowerDataCheck);
