import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import MPowerModal from '../../components/MPowerModal/MPowerModal';
import CrossIcon from '../../svgs/CrossIcon';
import './MPowerDataCheck.scss';
var MPowerDataCheck = function (_a) {
    var mPowerDataPending = _a.mPowerDataPending, mPowerDataRequestPending = _a.mPowerDataRequestPending, mPowerDataRequestError = _a.mPowerDataRequestError, mPowerDataRequestFulfilled = _a.mPowerDataRequestFulfilled, resetMPower = _a.resetMPower, mPowerRecord = _a.mPowerRecord;
    var history = useHistory();
    var _b = useState(''), ageGroup = _b[0], setAgeGroup = _b[1];
    var _c = useState(''), gender = _c[0], setGender = _c[1];
    var _d = useState('Scotland'), country = _d[0], setCountry = _d[1];
    var _e = useState(''), firstPartPostcode = _e[0], setFirstPartPostcode = _e[1];
    var _f = useState(false), disabled = _f[0], setDisabled = _f[1];
    var _g = useState(''), mPowerError = _g[0], setMPowerError = _g[1];
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    useEffect(function () {
        if (ageGroup === '' || gender === '' || country === '' || firstPartPostcode === '') {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [ageGroup, gender, country, firstPartPostcode]);
    useEffect(function () {
        if (mPowerDataRequestError) {
            setMPowerError((mPowerDataRequestError === null || mPowerDataRequestError === void 0 ? void 0 : mPowerDataRequestError.message) || (mPowerDataRequestError === null || mPowerDataRequestError === void 0 ? void 0 : mPowerDataRequestError.description));
        }
        else {
            setMPowerError('');
        }
    }, [mPowerDataRequestError]);
    useEffect(function () {
        if (mPowerError) {
            resetMPower();
        }
    }, [ageGroup, gender, country, firstPartPostcode]);
    if (mPowerRecord)
        return React.createElement(Redirect, { to: history.location.pathname + "/assessment" });
    return (React.createElement("div", { className: "mpower-modal" }, mPowerDataRequestFulfilled ? (React.createElement(MPowerModal, { resetMPower: resetMPower })) : (React.createElement("div", { className: "mpower-modal__modal-form" },
        React.createElement("div", { className: "mpower-modal__modal-form__header" },
            React.createElement("h2", { className: "mpower-modal__modal-form__header__title" }, "Welcome..."),
            React.createElement("div", { className: "mpower-modal__modal-form__header__container" },
                "Thank you for visiting our website. Before we begin, we are keen to know who is using ADAM to help us improve the website and related services, and to help us check that the service is being used by a wide range of users. It will take about one minute to provide this data. We only only ask for the first half of your postcode, so that you are not personally identifiable. This data is not stored against any form of user profile.",
                React.createElement("br", null),
                "If you are a carer, please fill in this form on behalf of the person living with dementia."),
            React.createElement(CrossIcon, { onClick: function () { return history.push(history.location.pathname); } })),
        React.createElement("form", { className: "mpower-modal__signup-form", autoComplete: "off", onSubmit: function (e) { return e.preventDefault(); }, role: "presentation" },
            React.createElement("div", { className: "mpower-modal__signup-form__content" },
                React.createElement("div", { className: "mpower-modal__signup-form__content__question" },
                    React.createElement("h3", { className: "mpower-modal__signup-form__content__question__text" }, "What age are you?"),
                    React.createElement("div", { className: "mpower-modal__signup-form__content__question__options" },
                        React.createElement("label", { htmlFor: "under-65" },
                            React.createElement("input", { type: "radio", id: "under-65", name: "age", value: "under-65", onChange: function (event) { return setAgeGroup(event.target.value); } }),
                            "Under 65"),
                        React.createElement("label", { htmlFor: "65-and-over" },
                            React.createElement("input", { type: "radio", id: "65-and-over", name: "age", value: "65-and-over", onChange: function (event) { return setAgeGroup(event.target.value); } }),
                            "65 and over"))),
                React.createElement("div", { className: "mpower-modal__signup-form__content__question" },
                    React.createElement("h3", { className: "mpower-modal__signup-form__content__question__text" }, "What gender do you identify with?"),
                    React.createElement("div", { className: "mpower-modal__signup-form__content__question__options" },
                        React.createElement("label", { htmlFor: "male" },
                            React.createElement("input", { type: "radio", id: "male", name: "gender", value: "male", onChange: function (event) { return setGender(event.target.value); } }),
                            "Male"),
                        React.createElement("label", { htmlFor: "female" },
                            React.createElement("input", { type: "radio", id: "female", name: "gender", value: "female", onChange: function (event) { return setGender(event.target.value); } }),
                            "Female"),
                        React.createElement("label", { htmlFor: "rather-not-say" },
                            React.createElement("input", { type: "radio", id: "rather-not-say", name: "gender", value: "rather not say", onChange: function (event) { return setGender(event.target.value); } }),
                            "I would rather not say"))),
                React.createElement("div", { className: "mpower-modal__signup-form__content__question" },
                    React.createElement("h3", { className: "mpower-modal__signup-form__content__question__text" }, "What country do you live in?"),
                    React.createElement("select", { onChange: function (event) { return setCountry(event.target.value); } },
                        React.createElement("option", { value: "Scotland" }, "Scotland"),
                        React.createElement("option", { value: "Northern Ireland" }, "Northern Ireland"),
                        React.createElement("option", { value: "England" }, "England"),
                        React.createElement("option", { value: "Wales" }, "Wales"),
                        React.createElement("option", { value: "Ireland (EU)" }, "Ireland (EU)"),
                        React.createElement("option", { value: "Other EU country" }, "Other EU country"),
                        React.createElement("option", { value: "Outside EU" }, "Outside EU"))),
                React.createElement("div", { className: "mpower-modal__signup-form__content__question" },
                    React.createElement("h3", { className: "mpower-modal__signup-form__content__question__text" },
                        "For UK and Ireland users only:",
                        React.createElement("br", null),
                        "Tell us the first half of your postcode"),
                    React.createElement("input", { type: "text", className: "mpower-modal__signup-form__content__question__text-answer", onChange: function (event) { return setFirstPartPostcode(event.target.value); } }),
                    React.createElement("p", { className: "mpower-modal__signup-form__content__question__extra-info" }, "e.g. \"EH3\" would be the first half of the postcode EH3 9DR"))),
            React.createElement("div", { className: "mpower-modal__signup-form__bottom" },
                React.createElement("button", { type: "submit", disabled: disabled || mPowerDataRequestPending, className: disabled
                        ? 'mpower-modal__signup-form__bottom__signup-button mpower-modal__signup-form__bottom__signup-button--disabled'
                        : 'mpower-modal__signup-form__bottom__signup-button', onClick: function () {
                        return mPowerDataPending({
                            sixtyFiveOrOver: ageGroup === '65-and-over',
                            gender: gender,
                            country: country,
                            firstPartPostcode: firstPartPostcode,
                        });
                    } }, "Continue to questionnaire")))))));
};
export default MPowerDataCheck;
