import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DropdownSearchInput } from '@lumera/lumera-ui';
import CrossIcon from '../../svgs/CrossIcon';
import './ChangeEmailForm.scss';
var ChangeEmailForm = function (_a) {
    var setChangeEmailFormShowing = _a.setChangeEmailFormShowing, changeEmailPending = _a.changeEmailPending;
    var history = useHistory();
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    return (React.createElement("div", { className: "change-email-form" },
        React.createElement("div", { className: "change-email-form__header" },
            "Change email",
            React.createElement(CrossIcon, { onClick: function () { return setChangeEmailFormShowing(false); } })),
        React.createElement("div", { className: "change-email-form__editable-fields" },
            React.createElement(DropdownSearchInput, { type: "text", labelText: "EMAIL", value: email, placeholder: "email", onChange: function (e) { return setEmail(e); }, required: true, name: "email-input" })),
        React.createElement("div", { className: "change-email-form__actions" },
            React.createElement("button", { type: "submit", disabled: true, className: 'change-email-form__actions__save-button change-email-form__actions__save-button--disabled', onClick: function () {
                    changeEmailPending(email);
                    history.push('/');
                } }, "Save"))));
};
export default ChangeEmailForm;
