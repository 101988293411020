import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Accordion, SectionTitle } from '@lumera/ts-lumera-ui';
import './Faqs.scss';
var Faqs = function (_a) {
    var getFAQsPending = _a.getFAQsPending, faqs = _a.faqs, getFAQsRequestFulfilled = _a.getFAQsRequestFulfilled, 
    // TODO: useHistory instead
    history = _a.history;
    useEffect(function () {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        getFAQsPending();
    }, []);
    return (React.createElement("div", { className: "faqs-page" },
        React.createElement("div", { className: "faqs-page__faq-container" },
            React.createElement(SectionTitle, { boldText: "Frequently Asked Questions", text: "" }),
            getFAQsRequestFulfilled &&
                faqs.map(function (faq) { return (React.createElement(Accordion, { key: faq.id, title: faq.question }, faq.answer)); }))));
};
export default Faqs;
